import styled, { css } from "styled-components";

interface InputStyleProps {
	error?: boolean;
	filled?: boolean;
}

export const Parent = styled.div`
	position: relative;
`;

const labelFloat = css`
	color: #5a6473;
	font-weight: 500;
	font-size: 12px;
	line-height: 15px;
	overflow: hidden;
	top: 10px;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: calc(100% - 30px);
`;

export const Input = styled.input<InputStyleProps>`
	background-color: #1e2c39;
	border: 1px solid
		${({ error }) => (error ? "#DC3545" : "#324455")};
	border-radius: 2px;
	color: #fafafa;
	font-size: 16px;
	height: 56px;
	line-height: 22px;
	padding: 12px 15px 0;
	transition: all 0.25s ease;
	width: 100%;

	&:focus {
		border-color: ${({ error }) =>
			error ? "#DC3545" : "#324455"};
		outline: none;

		& + label {
			color: ${({ error }) => (error ? "#DC3545" : "#324455")};
			${labelFloat}
		}
	}

	&:disabled {
		background-color: white;
		color: green;

		& + label {
			color: black;
			cursor: default;
		}
	}

	::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}
`;

export const Placeholder = styled.label<InputStyleProps>`
	color: #5a6473;
	cursor: text;
	font-size: 14px;
	font-weight: 400;
	line-height: 17px;
	left: 15px;
	position: absolute;
	top: 20px;
	transition: all 0.25s ease;

	${({ error }) =>
		error &&
		css`
			color: #dc3545;
			${labelFloat}
		`}

	${({ filled }) =>
		filled &&
		css`
			${labelFloat}
		`}
`;

export const ErrorMessage = styled.span`
	color: #dc3545;
	font-size: 12px;
	font-weight: 500;
	line-height: 15px;
	position: absolute;
	left: 0;
	bottom: 0px;
	transform: translateY(100%);
`;
