import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 151px;

  .decoration {
    position: absolute;
    top: 120px;
    width: calc(100% + 12vw);
  }

  @media only screen and (max-width: 1025px) {
    flex-direction: column;
  }
`;

export const Left = styled.article`
  width: 75%;
  position: relative;

  @media only screen and (max-width: 1025px) {
    width: 100%;
  }

  span {
    font-weight: 500;
    font-size: 14px;
    color: #fc9c44;
  }

  h1 {
    text-align: left;
    font-weight: 700;
    font-size: 42px;
    line-height: 50px;
    color: #fafafa;
    margin: 8px 0 16px 0;
  }

  .orangeSquare {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  @media only screen and (max-width: 1025px) {
    h1 {
      font-size: 30px;
      line-height: 36px;
    }
    .orangeSquare {
      display: none;
    }
  }
`;

export const Content = styled.article`
  align-self: center;
  width: 864px;
  margin-top: 83px;

  @media only screen and (max-width: 1025px) {
    flex-direction: column;

    img {
      max-width: 100%;
    }
  }

  p {
    text-align: left;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #8c96a0;
    margin-bottom: 42px;
  }

  @media only screen and (max-width: 1025px) {
    width: 100%;

    .buttonBlock14 {
      width: 315px;
    }
  }

  @media only screen and (max-width: 600px) {
    button {
      width: 100%;
    }
  }
`;

export const OrangeDot = styled.article`
  img {
    margin: 0 0 0 0;
  }
  @media only screen and (max-width: 1025px) {
    display: none;
  }
`;
