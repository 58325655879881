import ProductUrls from "@2ndmarket/components/src/helper/ProductUrls";
import { CardStep } from "../../components";

import { Title } from "../Block02/styles";
import { Container, BoxCard } from "./styles";

const Block08: React.FC = () => {
	return (
		<Container>
			<Title>[Recursos]</Title>
			<BoxCard>
				<CardStep
					img="/coin-icon.svg"
					title="Integração e moedas"
					text="A Cust.on é multimoedas. Armazene e proteja uma lista crescente de moedas digitais mais negociadas do mercado."
					full
					textRequest="Acessar"
					url="/integracao-moedas"
					width={30}
					height={28}
				/>
				<CardStep
					img="/glossary-icon.svg"
					title="Glóssário"
					text="Abitue-se com os termos do universo cripto e enriqueça o seu conhecimento
          e vocabulário."
					textRequest="Acessar"
					full
					url="/glossario"
					width={30}
					height={32}
				/>
				<CardStep
					img="/note-icon.svg"
					title="Conteúdo"
					text="Acesse conteúdos exclusivos sobre custódia e muitos outros assuntos sobre 
          a nova economia financeira."
					textRequest="Ver blog"
					url={ProductUrls.BLOG}
					full
					width={29}
					height={27}
				/>
			</BoxCard>
		</Container>
	);
};

export default Block08;
