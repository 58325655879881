import { ButtonPattern } from "../../components";
import { Block08 } from "..";
import {
	Container,
	Title,
	Content,
	BoxImage,
	Text,
	Infos,
} from "./styles";

const Seguro: React.FC = () => {
	return (
		<Container>
			<img
				src="./square-decoration2.svg"
				className="decoration1"
				alt=""
			/>
			<img
				src="./decoration3.svg"
				className="decoration2"
				alt=""
				width={4}
				height={4}
			/>
			<img
				src="./decoration3.svg"
				className="decoration3"
				alt=""
				width={4}
				height={4}
			/>
			<Title>
				<span>Seguro</span>
				<p>
					Tudo ao seu alcance! Fique fora da estatística de
					hacking.
				</p>
			</Title>
			<Content>
				<Infos gap={14} alternative>
					<p>
						<span>
							Hackers investem milhões para roubar bilhões
						</span>
						Na Cust.on, além de contar com um sistema que impede
						o roubo de chaves privadas. Nosso serviço de custódia
						para criptoativos conta com armazenamento frio (cold
						vault), baseada em uma conexão unidirecional
						exclusiva, permitindo que os dados sejam apenas
						enviados, nunca recebidos. Isso elimina totalmente o
						risco das chaves privadas serem roubadas por hackers.
					</p>
				</Infos>
				<ButtonPattern sideBar>Comece agora</ButtonPattern>
			</Content>
			<BoxImage>
				<img
					src="./square-decoration2.svg"
					className="decoration4"
					alt=""
				/>
				<img
					className="img-block"
					src="./img/IMG10.png"
					alt="Homem no notebook"
				/>
				<img
					className="img-block-mobile"
					src="./img/IMG10-Mobile.png"
					alt="Homem no notebook"
				/>
				<Text>
					<span>Cobertura</span>
					<h1>Seus bens cobertos em caso de:</h1>
					<Infos>
						<p>
							<img src="/check-icon.svg" alt="" />
							Hacks de terceiros do ambiente de armazenamento a
							frio.
						</p>
						<p>
							<img src="/check-icon.svg" alt="" />
							Cópia ou roubo de chaves privadas.
						</p>
						<p>
							<img src="/check-icon.svg" alt="" />
							Perda de material chave devido a desastres
							naturais.
						</p>
					</Infos>
					<ButtonPattern sideBar>Comece agora</ButtonPattern>
				</Text>
			</BoxImage>
			<Block08 />
		</Container>
	);
};

export default Seguro;
