import { ButtonPattern } from "../../components";

import { Container, Text } from "./styles";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

const Block06: React.FC = () => {
	return (
		<Container>
			<img
				src="./square-decoration.svg"
				className="decoration4"
				alt=""
			/>
			<Text>
				<span>Vantagens</span>
				<h1>
					Acesse múltiplas versões de soluções para custódia.
				</h1>
				<p>
					Soluções de custódia não são apenas computadores
					AirGapped em locais físicos protegidos.
				</p>
				<p>
					Nós combinamos cofres seguros, hardware criptográfico e
					governança organizacional para fornecer várias camadas
					de segurança e proteção dos seus ativos.
				</p>
				<Swiper
					pagination={{
						clickable: true,
					}}
					loop={true}
					autoplay={{
						delay: 2500,
						disableOnInteraction: false,
					}}
					modules={[Autoplay, Pagination]}
					className="mySwiper"
				>
					<SwiperSlide>
						<span>
							Chaves privadas geradas em ambiente off-line e
							nunca expostas em uma rede conectada a internet.
						</span>
					</SwiperSlide>
					<SwiperSlide>
						<span>
							Transações seguras por conexão unidirecional, os
							dados só podem sair do cofre, nunca entrar.
						</span>
					</SwiperSlide>
					<SwiperSlide>
						<span>
							Ativos mantidos em contas segregadas e seguradas
						</span>
					</SwiperSlide>
				</Swiper>
				<ButtonPattern sideBar>Comece agora</ButtonPattern>
			</Text>
			<img
				className="imageMobile"
				src="./img/IMG3-Mobile.png"
				alt=""
				width={394}
				height={394}
			/>
			<img
				className="imageDesktop"
				src="./img/IMG3.png"
				alt=""
				width={600}
				height={600}
			/>
		</Container>
	);
};

export default Block06;
