import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { ButtonPattern, CardCarousel } from "../../components";
import { Container, Left, Right } from "./styles";
import { useState } from "react";

const Block01: React.FC = () => {
	const [run, setRun] = useState(false);

	return (
		<Container>
			<img src="./Custon-BG.svg" alt="" className="decoration" />
			<Left>
				<span>Custódia qualificada</span>
				<h1>
					Guarde suas criptos de forma mais segura e
					personalizada.
				</h1>
				<p>
					Interaja com criptoativos acessando serviços de{" "}
					<b>custódia, seguro e staking</b> de ativos digitais
					por meio de uma tecnologia segura e acessível. Ideal
					para investidores institucionais e provedores de
					serviços.
				</p>
				<ButtonPattern sideBar>Comece agora</ButtonPattern>
			</Left>
			<Right>
				<img
					src="./img/IMG1.png"
					alt="Homem no computador"
					className="img-block"
					width={518}
					height={518}
				/>
				<img
					src="./img/IMG1-Mobile.png"
					alt="Homem no computador"
					className="img-block-mobile"
					width={394}
					height={394}
				/>
				<Swiper
					pagination={false}
					navigation={false}
					autoplay={{
						delay: 3000,
						disableOnInteraction: false,
					}}
					modules={[Autoplay, Pagination, Navigation]}
					onSlideChange={() => {
						setRun(!run);
					}}
					className="mySwiper"
				>
					<SwiperSlide>
						<CardCarousel
							run={run}
							abbreviation="ETH"
							img="/eth-icon.svg"
							name="Ethereum"
							value={14.7}
							width={16}
							height={16}
						/>
					</SwiperSlide>
					<SwiperSlide>
						<CardCarousel
							run={run}
							abbreviation="BTC"
							img="/btc-icon.svg"
							name="Bitcoin"
							value={0.18}
							width={16}
							height={16}
						/>
					</SwiperSlide>
					<SwiperSlide>
						<CardCarousel
							run={run}
							abbreviation="ADA"
							img="/ada-icon.svg"
							name="Cardano"
							value={0.41}
							width={16}
							height={16}
						/>
					</SwiperSlide>
					<SwiperSlide>
						<CardCarousel
							run={run}
							abbreviation="USDT"
							img="/usdt-icon.svg"
							name="Theter"
							value={1.18}
							width={16}
							height={16}
						/>
					</SwiperSlide>
				</Swiper>
			</Right>
		</Container>
	);
};

export default Block01;
