import React from "react";
import { ButtonPattern } from "../../components";
import { Container, Content, Left, OrangeDot } from "./styles";

const Block09: React.FC = () => {
  return (
    <Container>
      <Left>
        <span>Proteja seu dinheiro</span>
        <h1>
          Transferência e armazenamento de criptoativos de maneira eficiente e
          segura.
        </h1>
        <img src="/smallblock.svg" alt="" className="orangeSquare" />
      </Left>
      <Content>
        <p>
          Trazemos soluções para quem deseja imergir no universo de
          criptoativos. O principal objetivo é permitir que players
          institucionais e privados acessem e operem no mercado de criptomoedas
          mantendo e utilziando seus fundos com segurança.
        </p>
        <ButtonPattern sideBar>Comece agora</ButtonPattern>
      </Content>
      <OrangeDot>
        <img src="/smallblock.svg" alt="" />
      </OrangeDot>
    </Container>
  );
};

export default Block09;
