import styled from "styled-components";

export const Container = styled.div`
	margin-top: 200px;

	.decoration1,
	.decoration2 {
		position: absolute;
	}

	.decoration1 {
		right: 41.53%;
		top: 70.77%;
	}

	.decoration2 {
		right: 11.74%;
		top: 74.19%;
	}

	@media only screen and (max-width: 1025px) {
		margin-top: 94px;

		.decoration1 {
			right: 35.33%;
			top: 61.6%;
		}

		.decoration2 {
			right: 18.27%;
			top: 69.37%;
		}
	}
`;

export const Title = styled.div`
	max-width: 48vw;
	margin-bottom: 142px;

	span {
		font-weight: 500;
		font-size: 14px;
		color: #fc9c44;
	}

	p {
		font-weight: 700;
		font-size: 42px;
		line-height: 50px;
		color: #fafafa;
		margin-top: 8px;

		#crypto {
			color: #fc9c44;
		}
	}

	@media only screen and (max-width: 1025px) {
		max-width: 100%;
		margin-bottom: 84px;

		p {
			font-size: 30px;
			line-height: 36px;
			min-height: 144px;

			#crypto {
				height: 37px;
				min-height: 37px;
			}
		}
	}
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 50px;
	margin-bottom: 157px;

	img {
		width: fit-content;

		&.img-desk {
			@media only screen and (max-width: 1025px) {
				display: none;
			}
		}

		&.img-mobile {
			@media only screen and (min-width: 1025px) {
				display: none;
			}
		}

		@media only screen and (max-width: 1025px) {
			margin-left: -4vw;
		}
	}

	div {
		display: flex;
		gap: 28px;

		a {
			width: 174px;
			background-color: #1e2c39;
			font-weight: 500;
			font-size: 14px;
			color: #fc9c44;
			border-radius: 2px;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: 0.5s;

			:hover {
				background-color: #fc9c44;
				color: #fafafa;
				transition: 0.5s;
			}
		}

		@media only screen and (max-width: 1025px) {
			flex-direction: column;
			width: 100%;
			gap: 15px;

			button,
			a {
				width: 100%;
				height: 46px;
			}
		}
	}

	@media only screen and (max-width: 1025px) {
		margin-bottom: 100px;
	}
`;
