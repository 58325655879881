import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 80px;
	position: relative;

	.decoration {
		position: absolute;
		right: -6vw;
		top: 120px;
		width: calc(100% + 12vw);
	}

	@media only screen and (max-width: 1025px) {
		flex-direction: column;
	}
`;

export const Left = styled.article`
	width: 34vw;
	z-index: 1;

	span {
		font-weight: 500;
		font-size: 14px;
		color: #fc9c44;
	}

	h1 {
		font-weight: 700;
		font-size: 42px;
		line-height: 50px;
		color: #fafafa;
		margin: 8px 0 16px 0;
	}

	p {
		font-weight: 500;
		font-size: 16px;
		line-height: 22px;
		color: #8c96a0;

		b {
			color: #fafafa;
			font-weight: 500;
		}
	}

	button {
		margin-top: 28px;
		position: relative;
	}

	@media only screen and (max-width: 1025px) {
		width: 100%;

		h1 {
			font-size: 30px;
			line-height: 36px;
		}

		button {
			width: 100%;
			margin-top: 20px;
		}
	}
`;

export const Right = styled.div`
	position: relative;

	.img-block {
		display: flex;
	}

	.img-block-mobile {
		display: none;
	}

	.swiper {
		position: absolute;
		bottom: -80px;
		left: -50px;
		width: 100%;
		max-width: 402px;
		pointer-events: none;
	}

	@media only screen and (max-width: 1025px) {
		display: flex;
		width: 100%;
		margin-top: 60px;
		justify-content: center;

		.img-block-mobile {
			display: flex;
		}

		.img-block {
			display: none;
		}

		.swiper {
			display: flex;
			left: 40px;
			bottom: -130px;
			width: 90%;
		}
	}
`;
