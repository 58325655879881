import styled from "styled-components";

export const Container = styled.div`
	margin-top: 19px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media only screen and (max-width: 1024px) {
		button {
			display: none;
		}
	}
`;

export const Navigator = styled.div`
	display: flex;
	gap: 70px;
	align-items: center;

	@media only screen and (max-width: 1024px) {
		display: none;
	}

	.logoLink {
		cursor: pointer;
	}

	li {
		position: relative;
	}

	ul {
		list-style: none;
		display: flex;
		gap: 40px;
		padding: 0;
		margin: 0;

		a,
		button {
			font-weight: 500;
			font-size: 14px;
			color: #fafafa;
			transition: 0.5s;
			background-color: transparent;
			border: none;
			padding: 0;

			:hover {
				color: #fc9c44;
				transition: 0.5s;
			}
		}
	}

	&.login {
		width: 34vw;
		display: flex;
		justify-content: flex-end;

		a {
			color: #fafafa;
			font-size: 14px;
			font-weight: 500;
		}

		@media only screen and (max-width: 1440px) {
			width: 15vw;
		}

		@media only screen and (max-width: 1024px) {
			display: none;
		}
	}
`;

export const NavigatorMobile = styled.div`
	display: none;
	width: 100%;
	align-items: center;
	justify-content: space-between;

	@media only screen and (max-width: 1024px) {
		display: flex;

		button {
			background-color: transparent;
			border: none;
			display: block;
			padding: 10px 0 10px 20px;
		}
	}

	.mobileMenu {
		position: fixed;
		left: 0;
		top: -100%;
		width: 100%;
		height: 100vh;
		background-color: #fc9c44;
		padding: 18px 6vw;
		z-index: 8;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		transition: top 0.5s;

		&.opened {
			top: 0;
		}

		.menuHead {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.menu {
			list-style: none;
			padding: 0;

			li {
				color: #fafafa;
				font-weight: 500;
				font-size: 14px;

				&.submenu {
					padding-top: 10px;
					display: block;

					@media only screen and (max-width: 1024px) {
					}
				}

				a {
					color: #fafafa;
					font-weight: 500;
					font-size: 14px;
					padding: 10px 0;
					display: block;
				}

				ul {
					padding-left: 30px;
					padding-top: 10px;
					list-style: none;
				}
			}
		}

		.actionButton {
			button {
				background-color: #fff;
				width: 100%;
				color: #fc9c44;
				padding: 0;

				&:hover {
					background-color: #fff;
				}
			}
		}
	}
`;

export const Submenu = styled.div`
	display: flex;
	flex-direction: column;
	background-color: rgba(15, 30, 45, 0.45);
	border: 1px solid #1e2c39;
	backdrop-filter: blur(7.5px);
	border-radius: 2px;
	padding: 0 23px;
	position: absolute;
	top: 30px;
	width: 280px;
	z-index: 5;

	a {
		padding: 17px 0 !important;
		border-bottom: 1px solid #1e2c39 !important;

		:last-child {
			border: none !important;
		}
	}
`;
