import { ButtonPattern } from "../../components";
import {
	Container,
	Title,
	Content,
	Line,
	Box,
	LineIcon,
} from "./styles";

const Block03: React.FC = () => {
	return (
		<Container>
			<Title>
				<span>Custódia de alto padrão</span>
				<h1>
					Oferecemos mais tranquilidade para a sua rotina: ativos
					digitais seguros desde o primeiro dia.
				</h1>
				<img
					className="decoration1"
					src="./decoration1.svg"
					alt=""
					width={394}
					height={161}
				/>
			</Title>
			<Content>
				<Line>
					<Box>
						<div>
							<h1>Custódia qualificada</h1>
							<p>
								Proteja os seus ativos digitais com a nossa
								custódia de armazenamento Cold-Storage e Hot
								Wallet, hiper qualificadas e seguras.
								Indispensáveis para quem detém criptoativos em
								grande volume.
							</p>
						</div>
						<div>
							<LineIcon>
								<img
									src="/block-icon.svg"
									alt=""
									width={20}
									height={21}
								/>
								<span>Seus ativos digitais seguros</span>
							</LineIcon>
							<ButtonPattern sideBar alternative>
								Quero transferir
							</ButtonPattern>
						</div>
					</Box>
					<Box>
						<div>
							<h1>Custódia Self-Managed</h1>
							<p>
								Fornecemos uma ampla grade de funcionalidades
								dedicadas para aqueles que desejam obter controle
								sobre a gestão de suas chaves e acesso
								simplificado ao armazenamento.
							</p>
						</div>
						<div>
							<LineIcon>
								<img
									src="/key-icon.svg"
									alt=""
									width={19}
									height={18}
								/>
								<span>Suas chaves em suas mãos</span>
							</LineIcon>
							<ButtonPattern sideBar alternative>
								Quero investir
							</ButtonPattern>
						</div>
					</Box>
					<Box>
						<div>
							<h1>DeFi e CeFi</h1>
							<p>
								Integre por meio de API os serviços de custódia a
								Wallet Providers, DeFi, Marketplace ou Frontend
								de um ecossistema para usufruir da seguraça da
								Cust.on e gerenciar seus ativos digitais.
							</p>
						</div>
						<div>
							<LineIcon>
								<img
									src="/phone-icon.svg"
									alt=""
									width={20}
									height={21}
								/>
								<span>Acesse de foma objetiva</span>
							</LineIcon>
							<ButtonPattern sideBar alternative>
								Quero negociar
							</ButtonPattern>
						</div>
					</Box>
				</Line>
				<Line>
					<Box>
						<div>
							<h1>Seguro</h1>
							<p>
								Na Cust.on, além de contar com um sistema que
								impede o roubo de chaves privadas, os fundos
								estão cobertos por um seguro que reembolsa em até
								US$ 750 milhões por cofre.
							</p>
						</div>
						<div>
							<LineIcon>
								<img
									src="/wallet-icon.svg"
									alt=""
									width={20}
									height={21}
								/>
								<span>Seu dinheiro mais seguro</span>
							</LineIcon>
							<ButtonPattern sideBar alternative>
								Quero proteger
							</ButtonPattern>
						</div>
					</Box>
					<Box>
						<div>
							<h1>OTC</h1>
							<p>
								Realize operações de liquidação, swap ou compra
								de ativos digitais. Transacione no mercado
								privado de forma instantânea e segura, obtendo
								taxas competitivas e cotações personalizadas.
							</p>
						</div>
						<div>
							<LineIcon>
								<img
									src="/change-icon.svg"
									alt=""
									width={20}
									height={21}
								/>
								<span>Negocie de forma simples</span>
							</LineIcon>
							<ButtonPattern sideBar alternative>
								Quero negociar
							</ButtonPattern>
						</div>
					</Box>
					<Box>
						<div>
							<h1>Tokenization Engine</h1>
							<p>
								Registre seus Contratos Inteligentes,
								Stablecoins, Tokens, com uma tecnologia segura
								permitindo um ciclo de vida para Token Transfer,
								Minting, Burning e etc.
							</p>
						</div>
						<div>
							<LineIcon>
								<img
									src="/qrcode-icon.svg"
									alt=""
									width={20}
									height={21}
								/>
								<span>Tokenize com segurança</span>
							</LineIcon>
							<ButtonPattern sideBar alternative>
								Quero tokenizar
							</ButtonPattern>
						</div>
					</Box>
				</Line>
			</Content>
		</Container>
	);
};

export default Block03;
