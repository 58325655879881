import styled, { css } from "styled-components";

interface SelectProps {
	error?: boolean;
	filled?: boolean;
	small?: boolean;
	disabled?: boolean;
}

const labelFloat = css`
	font-size: 12px;
	line-height: 15px;
	top: 8px;
	font-weight: 500;
`;

export const Parent = styled.div<SelectProps>`
	background-color: #1e2c39;
	border: 1px solid;
	border-color: ${({ error }) =>
		error ? "#dc3545" : "#324455"};
	border-radius: 2px;
	font-weight: 400;
	font-size: 16px;
	height: ${({ small }) => (small ? 32 : 56)}px;
	line-height: 22px;
	position: relative;

	& > img {
		cursor: default;
		font-size: 10px;
		pointer-events: none;
		position: absolute;
		right: 20px;
		top: calc(50% + 2px);
		transform: translateY(-50%);
		color: #5a6473;
	}

	select:focus-visible {
		outline: none;
	}
`;

export const SelectCustom = styled.select<SelectProps>`
	appearance: none;
	background-color: transparent;
	color: #fafafa;
	font-size: 16px;
	font-weight: 400;
	height: 100%;
	left: 0;
	line-height: 22px;
	opacity: ${({ filled }) => (filled ? 1 : 0)};
	padding-left: 14px;
	padding-top: 12px;
	position: absolute;
	transition: all 0.25s ease;
	cursor: pointer;
	border: 0px;
	outline: 0px;
	width: 100%;

	&:disabled {
		color: red;
	}

	option {
		background-color: #0f1e2d;

		:disabled {
			color: #8c96a0;
		}
	}
`;

export const Placeholder = styled.label<SelectProps>`
	color: ${({ error, disabled }) =>
		error ? "#DC3545" : disabled ? "#5A6473" : "#5A6473"};
	cursor: text;
	font-size: 14px;
	font-weight: 500;
	line-height: 17px;
	left: 15px;
	position: absolute;
	top: 20px;
	transition: all 0.25s ease;

	${({ filled }) =>
		filled &&
		css`
			${labelFloat}
			color: #5A6473;
		`}
`;

export const ErrorMessage = styled.span`
	color: #dc3545;
	font-size: 12px;
	font-weight: 500;
	line-height: 15px;
	position: absolute;
	left: 0;
	bottom: 0px;
	transform: translateY(100%);
`;
