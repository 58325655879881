import { SEO } from "@2ndmarket/components";

import ProductUrls from "@2ndmarket/components/src/helper/ProductUrls";

interface Props {
  title: string;
  description: string;
}

const CustonSEO: React.FC<Props> = ({ title, description }) => {
  const url = ProductUrls.CUSTON;

  const params = {
    appName: "Cust-on",
    title: title,
    description: description,
    image: url + "/banner.jpg",
    imageWidth: "1080",
    imageHeight: "1080",
    themeColor: "#FC9C44",
    url: url,
  };

  return <SEO {...params}></SEO>;
};

export default CustonSEO;
