import { ButtonPattern } from "../../components";
import { Block08 } from "..";
import {
	Container,
	Title,
	Content,
	BoxImage,
	Text,
	Infos,
} from "./styles";

const OTC: React.FC = () => {
	return (
		<Container>
			<img
				src="./square-decoration2.svg"
				className="decoration1"
				alt=""
			/>
			<img
				src="./decoration3.svg"
				className="decoration2"
				alt=""
				width={4}
				height={4}
			/>
			<img
				src="./decoration3.svg"
				className="decoration3"
				alt=""
				width={4}
				height={4}
			/>
			<Title>
				<span>OTC</span>
				<p>
					Efetue liquidações ou compra de grandes quantidades de
					criptomoedas com a melhor condição do mercado.
				</p>
			</Title>
			<Content>
				<p>
					Solução para instituições, fundos de investimento e
					traders avançados.{" "}
					<b>
						Acesse diretamente aos fundos e minimize o risco da
						transação contra derrapagens e impactos no mercado
						com negociação OTC.
					</b>{" "}
					Transacione no mercado privado de forma instantânea e
					segura, obtendo taxas competitivas e cotações
					personalizadas.
				</p>
				<p>
					A negociação OTC também fornece um nível de anonimato
					não disponível em locais centralizados. As ordens são
					verdadeiramente anônimas, para que os clientes não
					sejam revelados como investidores significativos.
				</p>
				<ButtonPattern sideBar>Comece agora</ButtonPattern>
			</Content>
			<BoxImage>
				<img
					src="./square-decoration2.svg"
					className="decoration4"
					alt=""
				/>
				<img className="img-block" src="./img/IMG9.png" alt="" />
				<img
					className="img-block-mobile"
					src="./img/IMG9-Mobile.png"
					alt=""
				/>
				<Text>
					<span>Diferenciais</span>
					<h1>Menos burocracia e menor custo por transação.</h1>
					<Infos>
						<p>
							<img src="/check-icon.svg" alt="" />
							Rápida acessibilidade
						</p>
						<p>
							<img src="/check-icon.svg" alt="" />
							Serviço privado e total customizável conforme a sua
							demanda.
						</p>
						<p>
							<img src="/check-icon.svg" alt="" />
							Maior liquidez e confiabilidade.
						</p>
					</Infos>
					<ButtonPattern sideBar>Comece agora</ButtonPattern>
				</Text>
			</BoxImage>
			<Block08 />
		</Container>
	);
};

export default OTC;
