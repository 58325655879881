import styled from "styled-components";

export const Container = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 200px;
	margin-bottom: 90px;
	gap: 90px;

	.img-block {
		display: flex;
	}

	.img-block-mobile {
		display: none;
	}

	@media only screen and (max-width: 1025px) {
		display: block;
		flex-direction: column;
		margin-top: 90px;

		.img-block-mobile {
			display: flex;
		}

		.img-block {
			display: none;
		}
	}
`;

export const Images = styled.div`
	display: flex;
	position: relative;
	justify-content: flex-start;

	.swiper {
		width: 288px;
		height: 288px;
		position: absolute;
		bottom: -55px;
		right: -58px;
		border: 1px solid #fc9c44;
		box-shadow: 0px 10px 25px rgba(252, 156, 68, 0.12);
		border-radius: 4px;

		@media only screen and (max-width: 1025px) {
			right: 5%;
			bottom: -200px;
			width: 90%;
			height: 260px;
		}
	}

	.swiper-pagination-bullets {
		bottom: 36px;
	}

	.swiper-pagination-bullet {
		background-color: #324455;
		width: 4px;
		height: 4px;
	}

	.swiper-pagination-bullet-active {
		width: 6px;
		height: 6px;
		background-color: #fc9c44;
	}

	@media only screen and (max-width: 1025px) {
		display: flex;
		flex-direction: column;
		justify-contents: center;
		margin-top: 90px;

		.img-block-mobile {
			display: flex;
		}

		.img-block {
			display: none;
		}
	}
`;

export const Text = styled.div`
	max-width: 100%;

	span {
		font-weight: 500;
		font-size: 14px;
		color: #fc9c44;
	}

	h1 {
		font-weight: 700;
		font-size: 42px;
		line-height: 50px;
		color: #fafafa;
		margin-top: 8px;
	}

	p {
		font-weight: 500;
		font-size: 16px;
		line-height: 22px;
		color: #8c96a0;
		margin: 18px 0 31px 0;
	}

	@media only screen and (max-width: 1025px) {
		max-width: 100%;
		margin-top: 250px;

		h1 {
			font-weight: 600;
			font-size: 24px;
			line-height: 30px;
		}

		button {
			width: 100%;
		}
	}
`;
