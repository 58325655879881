import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  margin-top: 80px;
  position: relative;
  flex-direction: column;

  .decoration {
    position: absolute;
    right: -6vw;
    top: 120px;
    width: calc(100% + 12vw);
  }

  @media only screen and (max-width: 1025px) {
    flex-direction: column;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 28px;

  @media only screen and (max-width: 1025px) {
    flex-direction: column;
    margin-bottom: 77px;

    img {
      max-width: 100%;
    }
  }
`;

export const Glossary = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
  margin-bottom: 28px;
  border-bottom: 1px solid #1E2C39;
  width: calc(100% - 22vw);

  @media only screen and (max-width: 1025px) {
    width: 100%;
    gap: 10px;
  }

  &.last {
    border-bottom: none;
    margin-bottom: 120px;
  }

  @media only screen and (max-width: 1025px) {
    flex-direction: column;

    img {
      max-width: 100%;
    }
  }
`;

export const Left = styled.div`
  width: 34vw;
  margin-bottom: 57px;


  span {
    font-weight: 500;
    font-size: 14px;
    color: #fc9c44;
  }

  h1 {
    font-weight: 700;
    font-size: 42px;
    line-height: 50px;
    color: #fafafa;
    margin: 8px 0 16px 0;
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #8c96a0;
  }

  button {
    margin-top: 28px;
    position: relative;
  }

  @media only screen and (max-width: 1025px) {
    width: 100%;

    h1 {
      font-size: 30px;
      line-height: 36px;
    }

    button {
      width: 100%;
      margin-top: 20px;
    }
  }
`;

export const Text = styled.div`
  width: calc(100% - 170px);

  span {
    font-weight: 500;
    font-size: 14px;
    color: #fc9c44;
  }

  h1 {
    font-weight: 600;
    font-size: 32px;
    line-height: 50px;
    color: #fafafa;
    margin-top: 8px;
  }

  h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 21.94px;
    color: #fafafa;
    margin-top: 8px;
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #8c96a0;
    margin: 18px 0 31px 0;
  }
`;

export const IndexLetter = styled.div`
  width: 70px;
  margin-right: 100px;
  white-space: nowrap;
  font-weight: 700;
  font-size: 42px;
  line-height: 50px;
  color: #fafafa;
  margin-top: 50px;

  @media only screen and (max-width: 1025px) {
    margin: 0;
  }
`;


export const Line = styled.hr`  
  width: 61%;
  height: 0px;
  left: 287px;
  margin-top: -10px;
  margin-bottom: 50px;
  border: 1px solid #1E2C39;
`;

export const Navigator = styled.div`
  width: calc(100% - 22vw);
  display: flex;
  gap: 15px;
  align-items: center;
  margin-top: -30px;  
  border-bottom: 1px solid #1E2C39;
  border-top: 1px solid #1E2C39;

  @media only screen and (max-width: 1025px) {
    width: 100%;
    overflow-x: auto;
  }

  li {
    position: relative;
  }

  ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 13px;
    padding: 25px 0;
    margin: 0;

    a,
    button {
      font-weight: 500;
      font-size: 14px;
      color: #8c96a0;
      transition: 0.5s;
      background-color: transparent;
      border: none;
      padding: 0;
      white-space: nowrap;

      :hover {
        color: #fc9c44;
        transition: 0.5s;
      }
    }
  }
`;