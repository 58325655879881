import { useEffect } from "react";
import { ButtonPattern } from "../../components";
import { Container, Left } from "./styles";

const Block16: React.FC = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      var element = document.getElementById("crypto");
      var content = ["Bitcoin...", "Ethereum...", "Cardano...", "Theter...", "Solana..."];
      var part = 0;
      var partIndex = 0;
      var interval = setInterval(Type, 100);
    }

    function Type() {
      var text = content[part].substring(0, partIndex + 1);
      element!.innerHTML = text;
      partIndex++;

      if (text === content[part]) {
        clearInterval(interval);
        setTimeout(function () {
          interval = setInterval(Delete, 50);
        }, 1000);
      }
    }

    function Delete() {
      var text = content[part].substring(0, partIndex - 1);
      element!.innerHTML = text;
      partIndex--;

      if (text === "") {
        clearInterval(interval);

        if (part == content.length - 1) part = 0;
        else part++;

        partIndex = 0;

        setTimeout(function () {
          interval = setInterval(Type, 100);
        }, 200);
      }
    }
  }, []);

  return (
    <Container>
      <Left>
        <span>Venha fazer parte</span>
        <h1>
          Custodie tokens, smart contracts
          e criptomoedas como {" "}<label id="crypto" />
        </h1>
        <p>Ficou interessado?</p>
        <ButtonPattern className="buttonBlock16" sideBar>
          Comece agora
        </ButtonPattern>
      </Left>
      <img src="/block15-smalldots.svg" alt="" className="smallDots" />
    </Container>
  );
};

export default Block16;
