import styled from "styled-components";

export const Container = styled.div`
  margin-top: 100px;
  margin-bottom: 150px;

  @media only screen and (max-width: 1025px) {
    margin-bottom: 100px;
  }
`;

export const Title = styled.p`
  font-weight: 600;
  font-size: 18px;
  color: #fafafa;

  span {
    color: #fc9c44;
  }
`;

export const BoxCard = styled.div`
  display: flex;
  justify-content: center;
  gap: 28px;
  margin-top: 55px;

  @media only screen and (max-width: 1025px) {
    flex-direction: column;
    gap: 9px;
  }
`;
