import styled from "styled-components";

interface Props {
  center?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 255px 0 228px 0;
  position: relative;

  img {
    position: absolute;

    &.decoration1 {
      top: -150px;
      right: 2.81%;
    }
   
    &.decoration3 {
      top: 50px;
      right: 27.88%;
    }

    &.decoration4 {
      width: 236px;
      top: 75.27%;
      left: -27.57%;
    }
  }

  @media only screen and (max-width: 1025px) {
    display: none;
  }
`;

export const Title = styled.div`
  max-width: 70%;
  margin-bottom: 142px;

  span {
    font-weight: 500;
    font-size: 14px;
    color: #fc9c44;
  }

  h1 {
    margin-top: 8px;
    font-weight: 700;
    font-size: 42px;
    line-height: 50px;
    color: #fafafa;
  }

  img {
    position: absolute;
    top: -18px;
    right: -55px;
  }

  @media only screen and (max-width: 1025px) {
    max-width: 100%;
    margin-bottom: 223px;

    h1 {
      font-size: 30px;
      line-height: 36px;
    }

    img {
      top: 230px;
      width: 100%;
      left: 0;
    }
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;

  @media only screen and (max-width: 1025px) {
    gap: 9px;
  }
`;

export const Line = styled.div<Props>`
  display: flex;
  gap: 28px;

  @media only screen and (max-width: 1025px) {
    flex-direction: column;
    gap: 9px;
  }
`;
