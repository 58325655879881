import { Container, Left } from "./styles";

const Block12: React.FC = () => {
  return (
    <Container>
      <Left>
        <span>Diferenciais</span>
        <h1>Acesse um nível mais alto
          de tecnologia e segurança com a Cust.on!</h1>
        <p>Cada vez mais a segurança e privacidade tem sido preferência no ambiente
          escolhido para realizar investimentos reservas financeiras digitais.
          Por isso chegamos ao nível mais alto de tecnologia para entregar a solução você precisa
          para armazenar seus ativos digitais.</p>
      </Left>
      <img src="/boxes.svg" alt="" />
    </Container>
  );
};

export default Block12;
