import React from "react";
import { ButtonPattern } from "../../components";
import { Container, Content, Left, OrangeDot, Right } from "./styles";

const Block17: React.FC = () => {
  return (
    <>
      <Container>
        <Left>
          <span>Integração e moedas</span>
          <h1>
            Integramos nossos sistema com as principais criptomoedas do mercado,
            garantindo maior diversidade.
          </h1>
        </Left>
        <Right>
          <img src="/smallblock.svg" alt="" className="orangeSquare" />
          <img src="/block17-decor.svg" alt="" className="bigSquareRight" />
        </Right>
      </Container>

      <Content>
        <p>
          Possuimos planos de negócios sólidos que resolvem problemas reais,
          garantindo o máximo de segurança e liquidez no momento em que você
          decidir utilizar ou transferir suas criptos em nosso ecossistema.
        </p>
        <ButtonPattern className="buttonBlock17" sideBar>
          Comece agora
        </ButtonPattern>
      </Content>
      <OrangeDot>
        <img src="/smallblock.svg" alt="" />
      </OrangeDot>
    </>
  );
};

export default Block17;
