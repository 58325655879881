import { useEffect, useState } from "react";

import { LoaderContainer, BoxLoader } from "./styles";

interface LoaderProps {
  loaderState: boolean;
}

const Loader: React.FC<LoaderProps> = ({ loaderState }) => {
  const [loaderShow, setLoaderShow] = useState(loaderState);

  useEffect(() => {
    setLoaderShow(loaderState);
  }, [loaderState]);

  return (
    <>
      {loaderShow && (
        <LoaderContainer>
          <BoxLoader>
            <div className="square first" />
            <div className="square second" />
          </BoxLoader>
        </LoaderContainer>
      )}
    </>
  );
};

export default Loader;
