import { useEffect, useState } from "react";

import { useContext } from "react";
import AppContext from "../../helper/context";
import { TabCrypto } from "../../components";

import { Container, Carousel, Title, OrangeDot } from "./styles";

const Block18: React.FC = () => {
  const context = useContext(AppContext);
  const [cryptos, setCryptos] = useState<any[]>([]);

  useEffect(() => {
    setCryptos(context!.cryptos);
  }, [context]);

  return (
    <Container>
      <Title>[Cryptoativos]</Title>
      <Carousel>
        <TabCrypto cryptos={cryptos} />
      </Carousel>
      <OrangeDot>
        <img src="/smallblock.svg" alt="" />
      </OrangeDot>
    </Container>
  );
};

export default Block18;
