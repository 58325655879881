import React from "react";
import { ButtonPattern } from "../../components";
import { Container, Content, Left, OrangeDot } from "./styles";

const Block14: React.FC = () => {
  return (
    <Container>
      <Left>
        <span>Público-alvo</span>
        <h1>
          Quem administra grandes
          montantes necessita de um
          alto padrão de custódia.
        </h1>
        <img src="/smallblock.svg" alt="" className="orangeSquare" />
      </Left>
      <Content>
        <p>
          Trazemos soluções para quem deseja imergir no universo de criptoativos e
          atendemos um público exclusivo, interessados em usar serviços de criptografia
          e usufruir de novas oportunidades de negócios de um mercado com amplo espaço
          para o crescimento.
        </p>
        <ButtonPattern sideBar>Comece agora</ButtonPattern>
      </Content>
      <OrangeDot>
        <img src="/smallblock.svg" alt="" />
      </OrangeDot>
    </Container>
  );
};

export default Block14;
