import { useEffect } from "react";
import {
	Container,
	Top,
	Circle,
	Infos,
	Value,
	ProgressBar,
	Bottom,
	Bar,
} from "./styles";

interface CardProps {
	run: boolean;
	img: string;
	abbreviation: string;
	name: string;
	value: number;
	width?: number;
	height?: number;
}

const CardCarousel: React.FC<CardProps> = ({
	run,
	abbreviation,
	img,
	name,
	value,
	width,
	height,
}) => {
	var i = 0;
	function move() {
		if (i == 0) {
			i = 1;
			var elem = document.getElementById(abbreviation);
			var width = 1;
			var id = setInterval(frame, 25);
			//@ts-ignore
			function frame() {
				if (width >= 100) {
					clearInterval(id);
					i = 0;
				} else {
					width++;
					elem!.style.width = width + "%";
				}
			}
		}
	}

	useEffect(() => {
		move();
	});

	return (
		<Container>
			<Top>
				<Infos>
					<Circle>
						<img
							src={img}
							alt=""
							width={width}
							height={height}
						/>
					</Circle>
					<div>
						<p>{name}</p>
						<span>{abbreviation}</span>
					</div>
				</Infos>
				<Value>
					<img
						src="/send-icon.svg"
						alt=""
						width={width}
						height={height}
					/>
					<span>
						{value.toFixed(2).toString().replace(".", ",")}{" "}
						{abbreviation}
					</span>
				</Value>
			</Top>
			<ProgressBar>
				<Bar id={abbreviation} />
			</ProgressBar>
			<Bottom>
				<img
					src="/wallet-alternative.svg"
					alt=""
					width={width}
					height={height}
				/>
				<img
					src="/2nd-icon.svg"
					alt=""
					width={width}
					height={height}
				/>
			</Bottom>
		</Container>
	);
};

export default CardCarousel;
