import { useContext, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import AppContext from "../../helper/context";
import { CardCrypto } from "../../components";

import { Container, Carousel, Title } from "./styles";

const Block02: React.FC = () => {
	const context = useContext(AppContext);
	const [cryptos, setCryptos] = useState<any[]>([]);

	const [run, setRun] = useState(false);

	useEffect(() => {
		setCryptos(context!.cryptos);
	}, [context]);

	return (
		<Container>
			<Title>
				Mercado <span>[Crypto]</span>
			</Title>
			<Carousel>
				<Swiper
					spaceBetween={30}
					centeredSlides={false}
					autoplay={{
						delay: 2500,
						disableOnInteraction: false,
					}}
					pagination={false}
					navigation={false}
					modules={[Autoplay, Pagination, Navigation]}
					loop={true}
					loopFillGroupWithBlank={true}
					breakpoints={{
						640: {
							slidesPerView: 2,
							spaceBetween: 20,
						},
						768: {
							slidesPerView: 4,
							spaceBetween: 40,
						},
						1024: {
							slidesPerView: 5,
							spaceBetween: 30,
						},
					}}
					className="mySwiper"
				>
					{cryptos?.map((crypto, key) => (
						<>
							<SwiperSlide>
								<CardCrypto
									key={key}
									run={run}
									abbreviation={crypto.abbreviation}
									name={crypto.name}
									value={crypto.buy}
									variation={crypto.variation}
									width={15}
									height={24}
								/>
							</SwiperSlide>
						</>
					))}
				</Swiper>
			</Carousel>
		</Container>
	);
};

export default Block02;
