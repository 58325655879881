import React from "react";
import { ButtonPattern } from "../../components";
import { Container, Text, Picture } from "./styles";

const Block10: React.FC = () => {
  return (
    <Container>
      <Picture>
        <img src="./img/IMG4.png" alt="Homem no computador" className="img-block" />
        <img src="./img/IM4-Mobile.png" alt="Homem no computador" className="img-block-mobile" />
      </Picture>
      <Text>
        <span>Segurança de ponta a ponta</span>
        <h1>Guarde e gerencie os seus ativos digitais sem medo!</h1>
        <p>
          Uma plataforma com várias camadas de segurança, permitindo o
          armazenamento frio de ativos digitais e a estruturação de informações
          importantes para garantir sua integridade e a disponibilidade.
        </p>
        <p>
          Nossa tecnologia elimina os vetores tradicionais de ataques de
          blockchain, ao mesmo tempo em que oferece agilidade de criptografia
          incomparável.
        </p>
        <ButtonPattern sideBar>Comece agora</ButtonPattern>
      </Text>
    </Container>
  );
};

export default Block10;
