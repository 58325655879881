import { ButtonPattern } from "../../components";
import { Block08 } from "..";
import {
	Container,
	Title,
	Content,
	BoxImage,
	Text,
	Infos,
} from "./styles";

const Token: React.FC = () => {
	return (
		<Container>
			<img
				src="./square-decoration2.svg"
				className="decoration1"
				alt=""
			/>
			<img
				src="./decoration3.svg"
				className="decoration2"
				alt=""
				width={4}
				height={4}
			/>
			<img
				src="./decoration3.svg"
				className="decoration3"
				alt=""
				width={4}
				height={4}
			/>
			<Title>
				<span>Tokenization Engine</span>
				<p>
					Armazene seus tokens existentes offline e ainda usá-los
					para ganhar mais tokens.
				</p>
			</Title>
			<Content>
				<p>
					Trave seus criptoativos por um determinado período de
					tempo em troca de recompensas. Esse movimento ajuda a
					garantir que apenas dados e transações legítimos sejam
					adicionados a um blockchain. Staking bloqueia seus
					ativos para participar e ajudar a manter a segurança do
					blockchain dessa rede.
				</p>
				<ButtonPattern sideBar>Comece agora</ButtonPattern>
			</Content>
			<BoxImage>
				<img
					src="./square-decoration2.svg"
					className="decoration4"
					alt=""
				/>
				<img
					className="img-block"
					src="./img/IMG11.png"
					alt="Homem no celular"
				/>
				<img
					className="img-block-mobile"
					src="./img/IMG11-Mobile.png"
					alt="Homem no celular"
				/>
				<Text>
					<Infos alternative color="#FAFAFA">
						<p>
							<span>Ganhe rede passiva</span>
							Trave seus criptoativos por um determinado período
							de tempo em troca de recompensas. Esse movimento
							ajuda a garantir que apenas dados e transações
							legítimos sejam adicionados a um blockchain.
							Staking bloqueia seus ativos para participar e
							ajudar a manter a segurança do blockchain dessa
							rede.
						</p>
						<p>
							<span>Fácil de começar</span>
							Se você está procurando uma maneira de maximizar as
							recompensa, pela Cust.on você facilmente acessa os
							criptoativos mais promissores
						</p>
						<p>
							<span>Transacione mil vezes mais rápido</span>
							Contribua para a segurança e eficiência dos
							projetos de blockchain que você apoia.
						</p>
					</Infos>
					<ButtonPattern sideBar>Comece agora</ButtonPattern>
				</Text>
			</BoxImage>
			<Block08 />
		</Container>
	);
};

export default Token;
