import styled from "styled-components";

export const Container = styled.div`
  margin-top: 202px;
  position: relative;

  @media only screen and (max-width: 1025px) {
    margin-top: 95px;
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 48vw;
  margin-bottom: 242px;

  span {
    font-weight: 500;
    font-size: 14px;
    color: #fc9c44;
  }

  h1 {
    margin-top: 8px;
    font-weight: 700;
    font-size: 42px;
    line-height: 50px;
    color: #fafafa;
  }

  img {
    position: absolute;
    top: -18px;
    right: -55px;
  }

  @media only screen and (max-width: 1025px) {
    display: flex;
    max-width: 100%;
    margin-bottom: 123px;

    h1 {
      font-size: 30px;
      line-height: 36px;
    }

    img {
      position: relative;
      top: 30px;
      width: 100%;
      left: 0;

    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 98px;

  @media only screen and (max-width: 1025px) {
    gap: 57px;
  }
`;

export const Line = styled.div`
  display: flex;
  gap: 56px;

  @media only screen and (max-width: 1025px) {
    flex-direction: column;
  }
`;

export const Box = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: 100%;

  h1 {
    font-weight: 600;
    font-size: 18px;
    color: #fafafa;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #8c96a0;
    margin: 12px 0 23px 0;
  }

  @media only screen and (max-width: 1025px) {
    button {
      width: 100%;
    }
  }
`;

export const LineIcon = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 26px;
  gap: 12px;

  span {
    font-weight: 500;
    font-size: 14px;
    color: #fafafa;
  }
`;
