import styled from "styled-components";

interface Props {
  gap?: number;
  alternative?: boolean;
  color?: string;
}

export const Container = styled.div`
  margin-top: 153px;

  .decoration1,
  .decoration2,
  .decoration3 {
    position: absolute;

    @media only screen and (max-width: 1025px) {
      display: none;
    }
  }

  .decoration1 {
    right: 0;
    top: 14.17%;
  }

  .decoration2 {
    right: 341px;
    top: 314px;
  }

  .decoration3 {
    left: 123px;
    top: 610.5px;
  }

  .decoration4 {
    position: absolute;
    top: -70px;
    right: -6vw;

    @media only screen and (min-width: 1025px) {
      display: none;
    }
  }

  @media only screen and (max-width: 1025px) {
    margin-top: 64px;
  }
`;

export const Title = styled.article`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 54vw;
  margin-bottom: 84px;

  span {
    font-weight: 500;
    font-size: 14px;
    color: #fc9c44;
  }

  p {
    font-weight: 700;
    font-size: 42px;
    line-height: 50px;
    color: #fafafa;
  }

  @media only screen and (max-width: 1025px) {
    max-width: 100%;
    margin-bottom: 17px;

    p {
      font-size: 30px;
      line-height: 36px;
    }
  }
`;

export const Content = styled.article`
  margin: 0 14vw;
  display: flex;
  flex-direction: column;
  gap: 35px;
  margin-bottom: 100px;

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #8c96a0;
  }

  b {
    color: #fafafa;
  }

  @media only screen and (max-width: 1025px) {
    margin: 0;
    gap: 25px;
    margin-bottom: 150px;

    button {
      width: 100%;
    }
  }
`;

export const BoxImage = styled.div`
  position: relative;
  margin: 0 8vw;
  display: flex;
  align-items: center;
  gap: 6vw;
  margin-bottom: 111px;

  img {
    z-index: 1;
  }

  .img-block {
    display: flex;
  }

  .img-block-mobile {
    display: none;
  }    

  @media only screen and (max-width: 1025px) {
    flex-direction: column;
    margin: 0 0 95px 0;
    gap: 50px;

    .img-block-mobile {
      display: flex;
    }    

    .img-block {
      display: none;
    }

    .image-main {
      width: 100%;
    }
  }
`;

export const Text = styled.div`
  span {
    font-weight: 500;
    font-size: 14px;
    color: #fc9c44;
  }

  h1 {
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    margin: 9px 0 33px 0;
    color: #fafafa;
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #8c96a0;
  }

  button {
    margin-top: 35px;
  }

  @media only screen and (max-width: 1025px) {
    margin: 0 2vw;

    h1 {
      font-size: 24px;
      line-height: 30px;
      margin: 8px 0 25px 0;
    }

    button {
      width: 100%;
    }
  }
`;

export const Infos = styled.div<Props>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.gap ? props.gap : "22")}px;

  p {
    display: flex;
    flex-direction: ${(props) => props.alternative && "column"};
    gap: ${(props) => (props.alternative ? "8" : "10")}px;
    align-items: flex-start;

    span {
      color: ${(props) => (props.color ? props.color : "#fc9c44")};
      font-weight: ${(props) => props.color && "600"};
    }
  }

  @media only screen and (max-width: 1025px) {
    gap: 18px;
  }
`;
