import { useEffect, useState, useContext } from "react";
import { ReturnIcon } from "../Icons";
import {
	TextField,
	ButtonPattern,
	Select,
	Loader,
	PhoneInput,
} from "..";
import AppContext from "../../helper/context";
import {
	Container,
	Header,
	Text,
	Demo,
	Row,
	Box,
	Option,
	ContentButton,
	ContentValue,
	Processing,
	Top,
} from "./styles";

const SideBar: React.FC = () => {
	const context = useContext(AppContext);
	const [formStep, setFormStep] = useState("initial");
	const [segment, setSegment] = useState("");
	const [name, setName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [company, setCompany] = useState("");
	const [phone, setPhone] = useState("");
	const [message, setMessage] = useState("");
	const [selectOptions, setSelectOptions] = useState<string[]>(
		[]
	);
	const [cryptoOptions, setCryptoOptions] = useState([
		{
			value: "btc",
			label: "Bitcoin",
			disabled: false,
		},
		{
			value: "eth",
			label: "Ethereum",
			disabled: false,
		},
		{
			value: "ada",
			label: "Cardano",
			disabled: false,
		},
	]);

	const [arrayCrypto, setArrayCrypto] = useState([
		{
			crypto: "",
			value: "",
		},
	]);

	const segmentOptions = [
		{
			value: 1,
			label: "Provedores de Serviços",
		},
		{
			value: 2,
			label: "Casa de Câmbio",
		},
		{
			value: 3,
			label: "Investidores Individuais",
		},
		{
			value: 4,
			label: "Mineradores Cripto",
		},
		{
			value: 5,
			label: "Instituições Financeiras",
		},
		{
			value: 6,
			label: "Plataformas de Investimento",
		},
		{
			value: 7,
			label: "Bancos Digitais",
		},
		{
			value: 8,
			label: "Family Offices",
		},
		{
			value: 9,
			label: "Colecionadores NFT’s",
		},
		{
			value: 10,
			label: "Outro",
		},
	];
	const changeSelect = (type: string) => {
		const arrayTemp: string[] = [...selectOptions];

		if (arrayTemp.indexOf(type) === -1) {
			arrayTemp.push(type);
		} else {
			arrayTemp.splice(arrayTemp.indexOf(type), 1);
		}
		setSelectOptions(arrayTemp);
	};

	const addCrypto = () => {
		const arrayTemp = [...arrayCrypto];

		arrayTemp.push({
			crypto: "",
			value: "",
		});
		setArrayCrypto(arrayTemp);
	};

	const checkSelect = () => {
		const arrayTempCrypto = [...cryptoOptions];
		const arrayTemp = [...arrayCrypto];

		for (let i = 0; i < arrayTempCrypto.length; i++) {
			if (
				arrayTemp.find(
					(x) => x.crypto === arrayTempCrypto[i].value
				)
			) {
				arrayTempCrypto[i].disabled = true;
			} else {
				arrayTempCrypto[i].disabled = false;
			}
		}

		setCryptoOptions(arrayTempCrypto);
	};

	const removeCrypto = (id: number) => {
		const arrayTemp = [...arrayCrypto];

		arrayTemp.splice(id, 1);
		setArrayCrypto(arrayTemp);
	};

	const changeCrypto = (value: string, key: number) => {
		const arrayTemp = [...arrayCrypto];
		arrayTemp[key].crypto = value;

		setArrayCrypto(arrayTemp);
	};

	const changeCryptoValue = (value: string, key: number) => {
		checkSelect();
		const arrayTemp = [...arrayCrypto];
		arrayTemp[key].value = value;

		setArrayCrypto(arrayTemp);
	};

	const handleSubmit = (event: any) => {
		setFormStep("loading");
		event.preventDefault();
		const btcCyrpto = arrayCrypto.find((obj) => {
			return obj.crypto === "btc";
		});
		const ethCyrpto = arrayCrypto.find((obj) => {
			return obj.crypto === "eth";
		});
		const adaCyrpto = arrayCrypto.find((obj) => {
			return obj.crypto === "ada";
		});

		const optionsString = selectOptions.toString();

		const data = {
			name: name,
			full_name: name + " " + lastName,
			email: email,
			phone: phone,
			company: company,
			segment: segment,
			btc: btcCyrpto?.value ? btcCyrpto?.value : "0",
			eth: ethCyrpto?.value ? ethCyrpto?.value : "0",
			ada: adaCyrpto?.value ? adaCyrpto?.value : "0",
			usdt: "0",
			sol: "0",
			services: optionsString,
			message: message,
		};
		fetch("/api/form", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
			.then((response) => response.json())
			.then((responseData) => {
				setFormStep("sent");
			})
			.catch((error) => {
				console.error("Error:", error);
			});
	};

	return (
		<Container className={context?.sideBar ? "active" : ""}>
			{formStep == "initial" && (
				<>
					<Header>
						<ButtonPattern alternative sideBar>
							Cancelar
						</ButtonPattern>
						<button
							onClick={() => context?.setSideBar(false)}
							className="button-mobile"
							aria-label="Fechar"
						>
							<ReturnIcon />
						</button>
					</Header>
					<Text>
						<span>Conecte-se conosco</span>
						<h1>
							Proteja suas criptos de forma personalizada, sem
							burocracia.
						</h1>
					</Text>
					<Demo>
						<div>
							<span>Marque uma demo</span>
							<p>Em breve.</p>
						</div>
						<img
							src="/arrow-icon.svg"
							alt="Seta"
							width={15}
							height={10}
						/>
					</Demo>
					<form onSubmit={handleSubmit}>
						<h1> ...ou conecte-se conosco</h1>
						<Box>
							<span className="title">Sobre:</span>
							<Row>
								<TextField
									name="name"
									value={name}
									placeholder="Nome"
									onChange={(e: any) => setName(e.target.value)}
								/>
								<TextField
									name="lastName"
									placeholder="Sobrenome"
									onChange={(e: any) =>
										setLastName(e.target.value)
									}
									value={lastName}
								/>
							</Row>
							<Row>
								<TextField
									name="email"
									placeholder="E-mail"
									value={email}
									onChange={(e: any) => setEmail(e.target.value)}
								/>
								<PhoneInput
									type="phone"
									value={phone}
									name="phone"
									placeholder="Telefone"
									changeValue={setPhone}
								/>
							</Row>
							<Row>
								<TextField
									name="company"
									placeholder="Empresa"
									value={company}
									onChange={(e: any) =>
										setCompany(e.target.value)
									}
								/>
								<Select
									options={segmentOptions}
									onChange={(value) => setSegment(value)}
									value={segment}
									placeholder="Segmento"
								/>
							</Row>
						</Box>
						<Box>
							<span className="title">Quero transferir:</span>
							{arrayCrypto.map((item, key) => (
								<Row key={item.crypto}>
									<Select
										options={cryptoOptions}
										onChange={(value: string) =>
											changeCrypto(value, key)
										}
										value={item.crypto}
										placeholder="Criptomoeda"
									/>

									<ContentValue>
										<div className="value">
											<TextField
												name="teste"
												placeholder="Valor"
												value={item.value}
												type="number"
												onChange={(e: any) =>
													changeCryptoValue(e.target.value, key)
												}
											/>
											<span className="crypto-abv">
												{item.crypto
													? item.crypto.toUpperCase()
													: "-"}
											</span>
										</div>

										{key + 1 === arrayCrypto.length ? (
											<button
												className="add-button"
												onClick={() => addCrypto()}
												type="button"
												disabled={
													arrayCrypto.length ===
													cryptoOptions.length
												}
												aria-label="Adicionar criptomoeda"
											>
												<img
													src="/add-icon.svg"
													alt=""
													width={18}
													height={18}
												/>
											</button>
										) : (
											<button
												className="remove-button"
												onClick={() => removeCrypto(key)}
												type="button"
												aria-label="Remover criptomoeda"
											>
												<img
													src="/remove-icon.svg"
													alt=""
													width={18}
													height={18}
												/>
											</button>
										)}
									</ContentValue>
								</Row>
							))}
						</Box>
						<Box>
							<span className="title">Eu quero:</span>
							<Row row>
								<Option
									type="button"
									onClick={() => changeSelect("qualified")}
									active={selectOptions.includes("qualified")}
								>
									Custódia qualificada
								</Option>
								<Option
									type="button"
									onClick={() => changeSelect("safe")}
									active={selectOptions.includes("safe")}
								>
									Seguro
								</Option>
								<Option
									type="button"
									onClick={() => changeSelect("self-managed")}
									active={selectOptions.includes("self-managed")}
								>
									Custódia Self-Managed
								</Option>
							</Row>
							<Row row>
								<Option
									type="button"
									onClick={() => changeSelect("otc")}
									active={selectOptions.includes("otc")}
								>
									OTC
								</Option>
								<Option
									type="button"
									onClick={() => changeSelect("engine")}
									active={selectOptions.includes("engine")}
								>
									Tokenization Engine
								</Option>
								<Option
									type="button"
									onClick={() => changeSelect("defi")}
									active={selectOptions.includes("defi")}
								>
									DeFI e CeFI
								</Option>
							</Row>
						</Box>
						<Box>
							<span className="title">Dúvidas?</span>
							<textarea
								name="duvidas"
								placeholder="Mensagem"
								onChange={(e) => setMessage(e.target.value)}
							/>
						</Box>
						<ContentButton>
							<button type="submit">Enviar</button>
						</ContentButton>
					</form>
				</>
			)}

			{formStep == "loading" && (
				<Processing>
					<Top>
						<Header>
							<ButtonPattern alternative sideBar disabled>
								Cancelar
							</ButtonPattern>
							<button
								onClick={() => context?.setSideBar(false)}
								className="button-mobile"
								disabled
							>
								<ReturnIcon />
							</button>
						</Header>
						<Text>
							<span>Processando</span>
							<h1>
								Estamos enviando seus dados para nossa equipe...
							</h1>
						</Text>
					</Top>
					<Loader loaderState={true} />
					<p>Aguarde alguns instantes...</p>
				</Processing>
			)}

			{formStep == "sent" && (
				<Processing>
					<Top>
						<Header>
							<ButtonPattern alternative sideBar>
								Cancelar
							</ButtonPattern>
							<button
								onClick={() => context?.setSideBar(false)}
								className="button-mobile"
							>
								<ReturnIcon />
							</button>
						</Header>
						<Text>
							<span>Enviado!</span>
							<h1>
								Seus dados foram enviados para nossa equipe de
								especialistas!
							</h1>
							<p>
								Em breve, entraremos em contato com você através
								do e-mail ou celular informados. Obrigado por
								confiar na Cust.on!{" "}
							</p>
							<span>Custódia mais segura e personalizada.</span>
						</Text>
					</Top>
					<ButtonPattern className="return-button" sideBar>
						Retornar para página inicial
					</ButtonPattern>
				</Processing>
			)}
		</Container>
	);
};

export default SideBar;
