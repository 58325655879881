import {
	Container,
	Header,
	Box,
	Infos,
	Content,
} from "./styles";
import { CustonSocials } from "@2ndmarket/components/src/helper/ProductSocialNetworks";
import ProductUrls from "@2ndmarket/components/src/helper/ProductUrls";
import ProductCnpjs from "@2ndmarket/components/src/helper/ProductCnpjs";

const Footer: React.FC = () => {
	return (
		<Container>
			<img
				src="./2nd-decoration2.svg"
				className="decoration1"
				alt=""
				loading="lazy"
			/>
			<img
				src="./decoration3.svg"
				className="decoration2"
				alt=""
				loading="lazy"
				width={4}
				height={4}
			/>
			<Header>
				<Box>
					<img
						src="./img/logo-custon.png"
						alt="Logo Custon"
						width={190}
						height={28}
					/>
					<div>
						<a
							href={CustonSocials.FACEBOOK}
							target="_blank"
							rel="noreferrer"
						>
							<img
								src="/facebook-icon.svg"
								alt="Logo Facebook"
								loading="lazy"
							/>
						</a>
						<a
							href={CustonSocials.LINKEDIN}
							target="_blank"
							rel="noreferrer"
						>
							<img
								src="linkedin-icon.svg"
								alt="Logo Linkedin"
								loading="lazy"
							/>
						</a>
						<a
							href={CustonSocials.INSTAGRAM}
							target="_blank"
							rel="noreferrer"
						>
							<img
								src="instagram-icon.svg"
								alt="Logo Instagram"
								loading="lazy"
							/>
						</a>
					</div>
				</Box>
				<Box>
					<p>Confira todo o nosso ecossitema:</p>
					<a
						target="_blank"
						rel="noreferrer"
						href={ProductUrls.BANKAI}
					>
						Bank.ai
					</a>
					<a
						target="_blank"
						rel="noreferrer"
						href={ProductUrls.BRAEX}
					>
						Bra.ex
					</a>
					<a
						target="_blank"
						rel="noreferrer"
						href={ProductUrls.TOKENAIM}
					>
						Token.aim
					</a>
					<a
						target="_blank"
						rel="noreferrer"
						href={ProductUrls.SECOND_MARKET}
					>
						2ND Market
					</a>
				</Box>
			</Header>
			<Infos>
				<Content>
					<p className="links">
						<a
							href={ProductUrls.PRIVACY_POLICY}
							target="_blank"
							rel="noreferrer"
						>
							Política de Privacidade
						</a>
						<a
							href={ProductUrls.TERMS_AND_CONDITIONS}
							target="_blank"
							rel="noreferrer"
						>
							Termos de Uso
						</a>
						<a
							href={ProductUrls.SUPPORT}
							target="_blank"
							rel="noreferrer"
						>
							Preciso de Ajuda
						</a>
					</p>
					<p>
						© {new Date().getFullYear()}{" "}
						<a
							href={ProductUrls.SECOND_MARKET}
							target="_blank"
							rel="noreferrer"
						>
							Second Market
						</a>{" "}
						- Todos direitos reservados <br />
						CNPJ {ProductCnpjs.CUSTON}
					</p>
				</Content>
				<img
					src="./img/logo-2nd.png"
					alt="Logo 2ndMarket"
					loading="lazy"
				/>
			</Infos>
		</Container>
	);
};

export default Footer;
