import styled from "styled-components";

export const Parent = styled.div`
	font-family: Montserrat, sans-serif;
	margin-bottom: 9px;
	border-radius: 2px;
	width: 350px;
	height: 56px;
	position: relative;

	input:not(:placeholder-shown) + label {
		font-weight: bold;
		font-size: 12px;
		top: 12px;
	}

	input:focus + label {
		color: #5a6473;
		font-weight: 500;
		font-size: 12px;
		line-height: 15px;
		overflow: hidden;
		top: 16px;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: calc(100% - 30px);
	}
`;

export const Input = styled.input`
	font-family: Montserrat, sans-serif;
	color: #fafafa;
	background-color: #1e2c39;
	width: 100%;
	border: 1px solid #324455;
	height: 100%;
	padding: 25px 15px 9px 15px;
	transition: border-color 0.25s ease;

	&:focus {
		border-color: #324455;
		outline: none;
	}
`;

export const Placeholder = styled.label`
	font-family: Montserrat, sans-serif;
	font-size: 14px;
	font-weight: normal;
	color: #5a6473;
	position: absolute;
	left: 15px;
	top: 50%;
	transform: translateY(-50%);

	transition: all 0.25s ease;
`;
