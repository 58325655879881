import styled from "styled-components";

export const Content = styled.div`
	overflow: hidden;
	transition: filter 0.25s;
	padding: 0 15px;

	&.openSide {
		filter: blur(7.5px);
		pointer-events: none;
	}
`;
