import { ButtonPattern } from "../../components";
import { Container, Content, Text } from "./styles";
import Link from "next/link";

const BlockPage404: React.FC = () => {
  return (
    <Container>
      <img src="./img/404.png" alt="" className="decoration" />
      <Content>
        <Text>
          <h2>
            Perdido?
          </h2>
          <p>
            Hum... parece que a página que você estava
            procurando não existe. Verifique a URL ou clique
            no botão abaixo para retornar a página inicial.
          </p>
        </Text>
      </Content>
      <ButtonPattern>
        <Link href="/">
          Voltar para o início
        </Link>
      </ButtonPattern>
    </Container>
  );
};

export default BlockPage404;
