import React from "react";
import { CardStep } from "../../components";
import { Container, BoxCard, Title } from "./styles";

const Block11: React.FC = () => {
  return (
    <Container>
      <Title>[O que torna a <span>cust.on</span> segura]</Title>
      <BoxCard>
        <CardStep
          img="/number1-icon.svg"
          title="100% online"
          text="Todos os serviços que você precisa, 
          online e sem burocracia. Estar ao alcance de criptomoedas nunca foi tão fácil."
          textRequest="Conecte-se conosco"
          full
        />
        <CardStep
          img="/number2-icon.svg"
          title="Proteção a nível militar"
          text="Seus ativos armazenados em uma cold wallet totalmente offline e com nível de segurança militar."
          textRequest="Marque uma demo"
          full
        />
        <CardStep
          img="/number3-icon.svg"
          title="Conteúdo"
          text="A paraticidade de uma hot wallet sem 
          a preocupação do armazenamento das suas chaves públicas e privadas."
          textRequest="Venha para Cust.on"
          full
        />
      </BoxCard>
    </Container>
  );
};

export default Block11;
