import styled from "styled-components";

export const Container = styled.div`
  margin-top: 110px;
  margin-bottom: 150px;

  @media only screen and (max-width: 1025px) {
    margin-bottom: 100px;
  }
`;

export const Title = styled.p`
  font-weight: 600;
  font-size: 18px;
  color: #fafafa;
  margin-bottom: 30px;

  span {
    color: #fc9c44;
  }
`;

export const LineIcon = styled.div`
  display: inline-block;
  align-items: center;
  margin-bottom: -70px;

  img {
    width: 20%;
    height: auto;
    padding: 5px;
  }

  span {
    font-weight: 500;
    font-size: 14px;
    color: #fafafa;
  }

  @media only screen and (max-width: 1025px) {
    display: inline-block;
    gap: 9px;

    img {
      width: 50%;
      height: auto;
    }
  }
`;


export const BoxCard = styled.div`
  display: flex;
  justify-content: center;
  gap: 28px;
  margin-top: 55px;

  @media only screen and (max-width: 1025px) {
    flex-direction: column;
    gap: 9px;
  }
`;

export const OrangeDot = styled.article`
  display: none;

  @media only screen and (max-width: 1025px) {
    display: flex;
    margin-top: 70px;
    margin-right: 70px;
    justify-content: flex-end;
    
  }
`;
