import { useState } from "react";
import { ButtonPattern } from "../../components";
import Link from "next/link";

import {
	Container,
	Navigator,
	Submenu,
	NavigatorMobile,
} from "./styles";
import ProductUrls from "@2ndmarket/components/src/helper/ProductUrls";

const Header: React.FC = () => {
	const [subEspec, setSubEspec] = useState(false);
	const [subRec, setSubRec] = useState(false);
	const [mobileMenu, setMobileMenu] = useState(false);

	return (
		<Container>
			<Navigator>
				<Link href="/">
					<a className="logoLink">
						<img
							src="/logo-custon.svg"
							alt="Logo"
							width={190}
							height={28}
						/>
					</a>
				</Link>
				<ul>
					<li>
						<Link href="/">
							<a>Início</a>
						</Link>
					</li>
					<li>
						<Link href="/sobre">
							<a>Cust.on</a>
						</Link>
					</li>
					<li>
						<button onMouseEnter={() => setSubEspec(!subEspec)}>
							Especialidades
						</button>
						{subEspec && (
							<Submenu
								onMouseLeave={() => setSubEspec(!subEspec)}
							>
								<Link href="/custodia-qualificada">
									<a>Custódia qualificada</a>
								</Link>
								<Link href="/self-managed">
									<a>Custódia Self-Managed</a>
								</Link>
								<Link href="/defi-cefi">
									<a>DeFI e CeFI</a>
								</Link>
								<Link href="/otc">
									<a>OTC</a>
								</Link>
								<Link href="/seguro">
									<a>Seguro</a>
								</Link>
								<Link href="/token">
									<a>Tokenization Engine</a>
								</Link>
							</Submenu>
						)}
					</li>
					<li>
						<Link href="/segmentos">
							<a>Segmentos</a>
						</Link>
					</li>
					<li>
						<button onMouseEnter={() => setSubRec(!subRec)}>
							Recursos
						</button>
						{subRec && (
							<Submenu onMouseLeave={() => setSubRec(!subRec)}>
								<Link href="/integracao-moedas">
									<a>Integração e moedas</a>
								</Link>
								<a
									href={ProductUrls.BLOG}
									target="_blank"
									rel="noreferrer"
								>
									Conteúdo
								</a>
							</Submenu>
						)}
					</li>
				</ul>
			</Navigator>
			<NavigatorMobile>
				<Link href="/">
					<a className="logoLink">
						<img
							src="/img/logo-custon.png"
							alt="Logo"
							width={190}
							height={28}
							sizes="(max-width: 380px) 100vw, 380px"
						/>
					</a>
				</Link>
				<button
					onClick={() => setMobileMenu(!mobileMenu)}
					aria-label="menu"
				>
					<img
						src="/menu-open.svg"
						alt="Logo"
						width={21}
						height={13}
					/>
				</button>
				<div
					className={
						mobileMenu ? "mobileMenu opened" : "mobileMenu"
					}
				>
					<div className="menuHead">
						<Link href="/">
							<a className="logoLink">
								<img
									src="/img/logo-custon-white.svg"
									alt="Logo"
									width={150}
									height={22}
								/>
							</a>
						</Link>
						<button
							onClick={() => setMobileMenu(!mobileMenu)}
							aria-label="menu"
						>
							<img
								src="/menu-close.svg"
								alt="Logo"
								width={21}
								height={13}
							/>
						</button>
					</div>
					<ul className="menu">
						<li>
							<Link href="/">
								<a>Início</a>
							</Link>
						</li>
						<li>
							<Link href="/sobre">
								<a>Cust.on</a>
							</Link>
						</li>
						<li className="submenu">
							Especialidades
							<ul>
								<li>
									<Link href="/custodia-qualificada">
										<a>Custódia qualificada</a>
									</Link>
								</li>
								<li>
									<Link href="/self-managed">
										<a>Custódia Self-Managed</a>
									</Link>
								</li>
								<li>
									<Link href="/defi-cefi">
										<a>DeFI e CeFI</a>
									</Link>
								</li>
								<li>
									<Link href="/otc">
										<a>OTC</a>
									</Link>
								</li>
								<li>
									<Link href="/seguro">
										<a>Seguro</a>
									</Link>
								</li>
								<li>
									<Link href="/token">
										<a>Tokenization Engine</a>
									</Link>
								</li>
							</ul>
						</li>
						<li>
							<Link href="/segmentos">
								<a>Segmentos</a>
							</Link>
						</li>
						<li className="submenu">
							Recursos
							<ul>
								<li>
									<Link href="/integracao-moedas">
										<a>Integração e moedas</a>
									</Link>
								</li>
								<li>
									<Link href="/glossario">
										<a>Glossário</a>
									</Link>
								</li>
								<li>
									<a
										href={ProductUrls.BLOG}
										target="_blank"
										rel="noreferrer"
									>
										Conteúdo
									</a>
								</li>
							</ul>
						</li>
					</ul>
					<div className="actionButton">
						<ButtonPattern sideBar>Comece agora</ButtonPattern>
					</div>
				</div>
			</NavigatorMobile>
			<ButtonPattern sideBar>Comece agora</ButtonPattern>
		</Container>
	);
};

export default Header;
