import React from "react";
import { Content } from "../Block03/styles";
import { Container, Title, LineIcon, OrangeDot } from "./styles";

const Block15: React.FC = () => {
  return (
    <Container>
      <Title>[Custódia <span>ideal</span> para]</Title>
      <Content>
        <LineIcon>
          <img src="/block15-icon1.svg" alt="" />
          <img src="/block15-icon2.svg" alt="" />
          <img src="/block15-icon3.svg" alt="" />
          <img src="/block15-icon4.svg" alt="" />
          <img src="/block15-icon5.svg" alt="" />
          <img src="/block15-icon6.svg" alt="" />
          <img src="/block15-icon7.svg" alt="" />
          <img src="/block15-icon8.svg" alt="" />
          <img src="/block15-icon9.svg" alt="" />
        </LineIcon>
      </Content>
      <OrangeDot>
        <img src="/smallblock.svg" alt="" />
      </OrangeDot>
    </Container>
  );
};

export default Block15;
