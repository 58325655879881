import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 90px;
  margin-top: 100px;

  .img-block {
    display: flex;
  }

  .img-block-mobile {
    display: none;
  }

  @media only screen and (max-width: 1025px) {
    display: none;

    .img-block-mobile {
      display: flex;
    }    

    .img-block {
      display: none;
    }
  }
`;

export const Picture = styled.div`
  position: relative;

  .swiper {
    width: 288px;
    height: 288px;
    position: absolute;
    bottom: -55px;
    right: -58px;
    border: 1px solid #fc9c44;
    box-shadow: 0px 10px 25px rgba(252, 156, 68, 0.12);
    border-radius: 4px;
  }

  .swiper-pagination-bullets {
    bottom: 36px;
  }

  .swiper-pagination-bullet {
    background-color: #324455;
    width: 4px;
    height: 4px;
  }

  .swiper-pagination-bullet-active {
    width: 6px;
    height: 6px;
    background-color: #fc9c44;
  }

  img {
    border-radius: 2px;
  }
`;

export const Text = styled.div`
  max-width: 34vw;

  span {
    font-weight: 500;
    font-size: 14px;
    color: #fc9c44;
  }

  h1 {
    font-weight: 700;
    font-size: 32px;
    line-height: 50px;
    color: #fafafa;
    margin-top: 8px;
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #8c96a0;
    margin: 18px 0 31px 0;
  }
`;

export const BoxCard = styled.div`
  display: flex;
  justify-content: center;
  gap: 28px;
  margin-top: 55px;

  @media only screen and (max-width: 1025px) {
    flex-direction: column;
    gap: 9px;
  }
`;
