import styled from "styled-components";

export const Container = styled.div`
  padding: 50px 0 60px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;

  img {
    width: fit-content;
  }

  @media only screen and (max-width: 1025px) {
    height: auto;
    padding: 22px 0 40px 0;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 1025px) {
    flex-direction: column;
    margin-bottom: 77px;

    img {
      max-width: 100%;
    }
  }
`;

export const Text = styled.div`
  max-width: 38vw;
  
  span {
    font-weight: 500;
    font-size: 14px;
    color: #fc9c44;
  }

  h1 {
    font-weight: 700;
    font-size: 42px;
    line-height: 50px;
    color: #fafafa;
    margin: 8px 0 16px 0;
  }

  p,
  .focus {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #8c96a0;

    .focus {
      color: #fafafa;
    }
  }

  div {
    display: flex;
    align-items: center;
    margin-top: 29px;
    gap: 13px;
  }

  .time {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #fafafa;
  }

  @media only screen and (max-width: 1025px) {
    max-width: 100%;
    margin: 65px 0 41px 0;

    h1 {
      font-size: 30px;
      line-height: 36px;
    }
  }
`;
