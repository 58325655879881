export const ReturnIcon = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.22 10.1946L6.68 4.73459C6.97 4.44459 7.45 4.44459 7.74 4.73459C8.03 5.02459 8.03 5.50459 7.74 5.79459L3.56 9.97459H18.25C18.66 9.97459 19 10.3146 19 10.7246C19 11.1346 18.66 11.4746 18.25 11.4746H3.56L7.74 15.6546C8.03 15.9446 8.03 16.4246 7.74 16.7146C7.59 16.8646 7.4 16.9346 7.21 16.9346C7.02 16.9346 6.83 16.8646 6.68 16.7146L1.22 11.2546C0.929998 10.9646 0.929998 10.4846 1.22 10.1946Z"
        fill="#FAFAFA"
      />
    </svg>
  );
};
