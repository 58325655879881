import styled from "styled-components";

interface Props {
	active?: boolean;
	row?: boolean;
}

export const Container = styled.div`
	background-color: #0f1e2d;
	border: 1px solid #1e2c39;

	top: 0;
	bottom: 0;
	right: -100%;
	position: fixed;
	overflow-y: scroll;
	overflow-x: hidden;

	padding: 30px 1vw 39px 2vw;
	transition: right 0.5s;
	min-width: 600px;
	min-height: 100%;

	&.active {
		right: 1%;
	}

	form {
		margin-top: 25px;

		h1 {
			font-weight: 600;
			font-size: 16px;
			color: #fafafa;
		}
	}

	@media only screen and (max-width: 1025px) {
		padding: 26px 4vw;
		position: fixed;
		right: -150%;
		min-width: 100%;
		transition: right 0.5s;

		&.active {
			right: 0;
			position: fixed;
		}
	}
`;

export const Header = styled.div`
	display: flex;
	justify-content: end;

	button {
		width: 115px;
	}

	.button-mobile {
		background-color: transparent;
		border: none;
		padding: 0;
		width: fit-content;

		:disabled {
			path {
				fill: #5a6473;
			}
		}
	}

	@media only screen and (max-width: 1025px) {
		justify-content: flex-start;

		.alternative {
			display: none;
		}
	}

	@media only screen and (min-width: 1025px) {
		.button-mobile {
			display: none;
		}
	}
`;

export const Text = styled.article`
	max-width: 400px;
	margin-top: 1px;

	span {
		font-weight: 500;
		font-size: 14px;
		color: #fc9c44;
	}

	h1 {
		font-weight: 600;
		font-size: 25px;
		line-height: 36px;
		color: #fafafa;
		margin-top: 12px;
	}

	p {
		font-weight: 500;
		font-size: 16px;
		line-height: 22px;
		color: #8c96a0;
		margin: 18px 0;
	}

	@media only screen and (max-width: 1025px) {
		margin-top: 1px;

		h1 {
			font-size: 24px;
			line-height: 25px;
		}
	}
`;

export const Demo = styled.div`
	display: flex;
	justify-content: space-between;
	width: 550px;
	border: 1px solid #1e2c39;
	border-radius: 2px;
	padding: 17px 20px;
	margin-top: 25px;

	span {
		font-weight: 600;
		font-size: 18px;
		color: #fafafa;
	}

	p {
		font-weight: 500;
		font-size: 16px;
		line-height: 22px;
		color: #5a6473;
	}

	@media only screen and (max-width: 1025px) {
		width: 100%;
		padding: 18px 15px;
	}
`;

export const Row = styled.div<Props>`
	display: flex;
	gap: 8px;

	@media only screen and (max-width: 1025px) {
		flex-direction: ${(props) => !props.row && "column"};
		flex-wrap: wrap;
	}

	div {
		width: 100%;
	}
`;

export const Box = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-top: 20px;

	.title {
		font-weight: 500;
		font-size: 14px;
		color: #5a6473;
	}

	textarea {
		background-color: #1e2c39;
		border: 1px solid #324455;
		border-radius: 2px;
		resize: none;
		height: 85px;
		padding: 15px;
		font-weight: 400;
		font-size: 16px;
		line-height: 22px;
		color: #fafafa;

		:focus-visible {
			outline: none;
		}

		::-webkit-input-placeholder {
			color: #5a6473;
			font-family: Montserrat, sans-serif;
			font-weight: 500;
			font-size: 14px;
		}
	}
`;

export const Option = styled.button<Props>`
	width: fit-content;
	height: 46px;
	border: none;
	border-radius: 2px;
	background-color: #1e2c39;
	padding: 0 22px;
	font-weight: 500;
	font-size: 14px;
	color: ${(props) => (props.active ? "#FC9C44" : "#5a6473")};
	border: ${(props) =>
		props.active
			? "1px solid #FC9C44"
			: "1px solid transparent"};

	@media only screen and (max-width: 1025px) {
		padding: 0 14px;
		min-width: fit-content;
	}
`;

export const ContentButton = styled.div`
	margin-top: 20px;
	display: flex;
	justify-content: flex-end;

	button {
		background-color: #fc9c44;
		width: 174px;
		height: 46px;
		border: none;
		box-shadow: 0px 10px 25px rgba(252, 156, 68, 0.12);
		border-radius: 2px;
		font-weight: 500;
		font-size: 14px;
		color: #fafafa;
		transition: 0.5s;

		:hover {
			background-color: #fa8231;
			transition: 0.5s;
		}
	}

	@media only screen and (max-width: 1025px) {
		button {
			width: 100%;
		}
	}
`;

export const ContentValue = styled.div`
	display: flex;
	gap: 9px;
	min-width: 310px;

	.value {
		position: relative;

		.crypto-abv {
			position: absolute;
			top: calc(50% - 8px);
			right: 14px;
			font-weight: 500;
			font-size: 14px;
			color: #5a6473;
		}
	}

	.add-button {
		min-width: 56px;
		border-radius: 2px;
		background-color: #fc9c44;
		border: none;
	}

	.remove-button {
		min-width: 56px;
		border-radius: 2px;
		background-color: #1e2c39;
		border: none;
	}
`;

export const Processing = styled.div`
	height: calc(100vh - 75px);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 60px;

	p {
		font-weight: 400;
		font-size: 16px;
		color: #8c96a0;
	}

	.return-button {
		width: 315px;

		@media only screen and (max-width: 1025px) {
			width: 100%;
		}
	}
`;

export const Top = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;
