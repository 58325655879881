import { ButtonPattern, Slide } from "../../components";
import { Container, Text, Images } from "./styles";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

const Block04: React.FC = () => {
	return (
		<Container>
			<Images>
				<img
					src="./img/IMG2.png"
					alt="Cofre"
					className="img-block"
					width={600}
					height={600}
				/>
				<img
					src="./img/IMG2-Mobile.png"
					alt="Cofre"
					className="img-block-mobile"
					width={394}
					height={394}
				/>
				<Swiper
					pagination={{
						clickable: true,
					}}
					loop={true}
					autoplay={{
						delay: 2500,
						disableOnInteraction: false,
					}}
					modules={[Autoplay, Pagination]}
					className="mySwiper"
				>
					<SwiperSlide>
						<Slide
							img="/exchange-icon.svg"
							text="Exchanges"
							height={48}
							width={40}
						/>
					</SwiperSlide>
					<SwiperSlide>
						<Slide
							width={49}
							height={51}
							img="/services-icon.svg"
							text="Provedores de Serviços"
						/>
					</SwiperSlide>
					<SwiperSlide>
						<Slide
							width={50}
							height={48}
							img="/cambio-icon.svg"
							text="Casa de Câmbio"
						/>
					</SwiperSlide>
					<SwiperSlide>
						<Slide
							width={50}
							height={41}
							img="/investor-icon.svg"
							text="Investidores Individuais"
						/>
					</SwiperSlide>
					<SwiperSlide>
						<Slide
							width={45}
							height={45}
							img="/miners-icon.svg"
							text="Mineradores Cripto"
						/>
					</SwiperSlide>
					<SwiperSlide>
						<Slide
							width={52}
							height={49}
							img="/institution-icon.svg"
							text="Instituições Financeiras"
						/>
					</SwiperSlide>
					<SwiperSlide>
						<Slide
							width={48}
							height={46}
							img="/platform-icon.svg"
							text="Plataformas de Investimento"
						/>
					</SwiperSlide>
					<SwiperSlide>
						<Slide
							width={42}
							height={46}
							img="/digital-icon.svg"
							text="Bancos Digitais"
						/>
					</SwiperSlide>
					<SwiperSlide>
						<Slide
							width={46}
							height={44}
							img="/offices-icon.svg"
							text="Family Offices"
						/>
					</SwiperSlide>
					<SwiperSlide>
						<Slide
							width={46}
							height={52}
							img="/collector-icon.svg"
							text="Colecionadores NFT’s"
						/>
					</SwiperSlide>
				</Swiper>
			</Images>
			<Text>
				<span>Segmentos atendidos</span>
				<h1>
					Segurança planejada para atender às suas demandas.
				</h1>
				<p>
					Uma solução de custódia pode oferecer suporte aos
					usuários em todos os níveis de atividade e aumentar
					para níveis maiores de volume ao longo do tempo.
					Usufrua dos serviços em cripto e capture novas
					oportunidades de negócios com ampla gama de
					crescimento.
				</p>
				<ButtonPattern sideBar>Comece agora</ButtonPattern>
			</Text>
		</Container>
	);
};

export default Block04;
