import styled from "styled-components";

interface Props {
  width?: number;
}

export const Container = styled.button<Props>`
  background-color: #fc9c44;
  width: ${(props) => (props.width ? props.width : "174")}px;
  height: 46px;
  border: none;
  box-shadow: 0px 10px 25px rgba(252, 156, 68, 0.12);
  border-radius: 2px;
  font-weight: 500;
  font-size: 14px;
  color: #fafafa;
  transition: 0.5s;
  
  :hover {
    background-color: #fa8231;
    transition: 0.5s;
  }

  &:disabled {
    background-color: #1e2c39;
    cursor: default;
    color: #5a6473;
  }

  &.alternative {
    background-color: #1e2c39;
    box-shadow: none;
    color: #fc9c44;

    span {
      color: #fc9c44 !important;
    }

    :hover {
      background-color: #fc9c44;
      transition: 0.5s;
      color: #fafafa;

      span {
        color: #fafafa !important;
      }
    }
  }
`;
