import styled from "styled-components";

export const Container = styled.div`
  background-color: #0f1e2d;
  border: 1px solid #1e2c39;
  border-radius: 3px;
  padding: 20px;

  @media only screen and (max-width: 1025px) {
    padding: 20px 15px;
  }
`;

export const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Infos = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;

  p {
    font-weight: 500;
    font-size: 16px;
    color: #fafafa;
  }

  span {
    font-weight: 600;
    font-size: 12px;
    color: #8c96a0;
  }
`;

export const Circle = styled.div`
  width: 46px;
  height: 46px;
  border: 1px solid #324455;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

export const Value = styled.div`
  background-color: #1e2c39;
  border-radius: 2px;
  padding: 10px 15px;
  display: flex;
  gap: 8px;

  span {
    font-weight: 600;
    font-size: 12px;
    color: #fafafa;
  }
`;

export const ProgressBar = styled.div`
  width: 100%;
  height: 8px;
  background-color: #1e2c39;
  margin: 21px 0 13px 0;
`;

export const Bar = styled.div`
  width: 1%;
  height: 100%;
  background-color: #fc9c44;
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
