import React, { HTMLInputTypeAttribute } from "react";
import InputMask from "react-input-mask";
import { ErrorMessage, Input, Parent, Placeholder } from "./styles";

export interface TextFieldProps {
  name: string;
  placeholder: string;
  value?: any;
  error?: string;
  disabled?: boolean;
  mask?: string | Array<string | RegExp>;
  maskPlaceholder?: string | null;
  type?: HTMLInputTypeAttribute;
  readOnly?: boolean;
  onChange?: any;
  onKeyPress?: (value: any) => void;
  maxLength?: number;
}

const TextField: React.FC<TextFieldProps> = ({
  name,
  value,
  placeholder,
  error,
  disabled,
  mask,
  maskPlaceholder,
  type = "text",
  readOnly = false,
  onChange,
  onKeyPress,
  maxLength,
}) => {
  return (
    <Parent>
      {mask && typeof window !== "undefined" ? (
        <InputMask
          mask={mask}
          onChange={e => onChange && onChange(e.target.value)}
          value={value}
          disabled={disabled}
          readOnly={readOnly}
          maskPlaceholder={maskPlaceholder}>
          <Input
            id={name}
            name={name}
            error={!!error}
            disabled={disabled}
            filled={value?.length > 0}
            maxLength={maxLength}
          />
        </InputMask>
      ) : (
        <Input
          id={name}
          type={type}
          name={name}
          error={!!error}
          value={value}
          readOnly={readOnly}
          onChange={onChange}
          disabled={disabled}
          onKeyPress={onKeyPress}
          filled={value?.length > 0}
          maxLength={maxLength}
        />
      )}

      <Placeholder
        error={!!error}
        htmlFor={name}
        filled={value != undefined && value !== ""}>
        {placeholder}
      </Placeholder>

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Parent>
  );
};

TextField.displayName = "TextFiled";
export default TextField;
