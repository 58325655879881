import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 0 4vw;
	align-items: center;
	position: relative;
	gap: 50px;

	.imageMobile {
		display: none;
	}

	.imageDesktop {
		display: flex;
	}

	.decoration4 {
		display: none;

		@media only screen and (max-width: 1025px) {
			display: none;
			position: absolute;
			left: -60px;
			top: -160px;
		}
	}

	@media only screen and (max-width: 1025px) {
		flex-direction: column;
		gap: 50px;
		margin: 0;

		.imageDesktop {
			display: none;
		}

		.imageMobile {
			display: flex;
		}
	}
`;

export const Text = styled.article`
	display: flex;
	flex-direction: column;
	width: 40%;

	span {
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		color: #fc9c44;
	}

	h1 {
		font-weight: 600;
		font-size: 32px;
		line-height: 36px;
		color: #fafafa;
		margin: 10px 0 20px 0;

		@media only screen and (max-width: 1025px) {
			font-size: 24px;
			line-height: 30px;
		}
	}

	p {
		font-weight: 500;
		font-size: 16px;
		line-height: 22px;
		color: #8c96a0;
		margin-bottom: 13px;
	}

	button {
		margin-top: 35px;

		@media only screen and (max-width: 1025px) {
			width: 100%;
		}
	}

	.swiper {
		max-width: 100%;
		height: 80px;
		margin-top: 10px;
	}

	.swiper-pagination-bullets {
		bottom: 0;
		text-align: left;
	}

	.swiper-pagination-bullet {
		background-color: #324455;
		width: 4px;
		height: 4px;
	}

	.swiper-pagination-bullet-active {
		width: 6px;
		height: 6px;
		background-color: #fc9c44;
	}

	@media only screen and (max-width: 1025px) {
		diplay: flex;
		width: 100%;
		height: auto;
		font-size: 24px;
		line-height: 30px;
	}
`;
