import styled from "styled-components";

export const Container = styled.div`
  padding: 150px 0 60px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 80vh;

  img {
    width: 100%;

    @media only screen and (min-width: 1025px) {
      max-width: 650px;
    }
  }

  @media only screen and (max-width: 1025px) {
    height: auto;
    padding: 60px 0 40px 0;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 28px;

  @media only screen and (max-width: 1025px) {
    flex-direction: column;

    img {
      max-width: 100%;
    }
  }
`;

export const Text = styled.div`
  max-width: 38vw;

  h2 {
    text-align: center;
    font-weight: 500;
    font-size: 42px;
    line-height: 50px;
    color: #fafafa;
    margin: 8px 0 26px 0;
  }

  p,
  .focus {
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: #fafafa;
    margin: 16px 0 16px 0;
    text-align: center;

    .focus {
      color: #fafafa;
    }
  }

  div {
    display: flex;
    align-items: center;
    margin-top: 29px;
    gap: 13px;
  }

  @media only screen and (max-width: 1025px) {
    max-width: 100%;
    margin: 65px 0 41px 0;

    h2 {
      font-size: 30px;
      line-height: 36px;
    }
  }
`;
