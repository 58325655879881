import { ButtonPattern } from "../../components";
import { Block08 } from "..";
import {
	Container,
	Title,
	Content,
	BoxImage,
	Text,
	Infos,
} from "./styles";

const DefiCefi: React.FC = () => {
	return (
		<Container>
			<img
				src="./square-decoration2.svg"
				className="decoration1"
				alt=""
			/>
			<img
				src="./decoration3.svg"
				className="decoration2"
				alt=""
				width={4}
				height={4}
			/>
			<img
				src="./decoration3.svg"
				className="decoration3"
				alt=""
				width={4}
				height={4}
			/>
			<Title>
				<span>DeFI e CefI</span>
				<p>
					A tecnologia Blockchain muda a forma como interagimos
					com o mundo financeiro.
				</p>
			</Title>
			<Content>
				<p>
					Não se limite a segurança de exchanges e wallets, suas
					finanças digitais devem estar protegidas com o maior
					nível de tecnologia. Você tem a liberdade de utilizar
					qualquer ambiente cripto, e seus ativos ativos merecem
					ser custodiados de maneira independente na Cust.on.
				</p>
				<Infos gap={14} alternative>
					<p>
						<span>API</span>
						Mais adequado para uma interação automatizada,
						algorítmica com protocolos e uso que exigem interação
						em tempo real. Criação de mercado DEX, empréstimos,
						bots de liquidação, minting e etc.
					</p>
					<p>
						<span>Extensão do navegador</span>
						Extensão de navegador compatível com MetaMask que
						permite que os clientes interajam com os protocolos
						DeFi por meio de seu aplicativo da web.
					</p>
					<p>
						<span>Integração WalletConnect</span>A integração
						fornece uma maneira conveniente de implantar ativos
						digitais, apoiada pela poderosa governança e
						segurança da custódia da Cust.on.
					</p>
					<p>
						<span>Painel Portfólio</span>
						Enxergue seus ativos em um painel com todo o seu
						portfólio custodiado pela CUST.ON, efetue transações
						crypto para wallets externas e visualize as
						traqnsações feitas para melhor experiência de
						compliance e acesse ambientes CeFi.
					</p>
				</Infos>
				<ButtonPattern sideBar>Comece agora</ButtonPattern>
			</Content>
			<BoxImage>
				<img
					src="./square-decoration2.svg"
					className="decoration4"
					alt=""
				/>
				<img
					className="img-block"
					src="./img/IMG8.png"
					alt="Cofre Bitcoin"
				/>
				<img
					className="img-block-mobile"
					src="./img/IMG8-Mobile.png"
					alt="Cofre Bitcoin"
				/>
				<Text>
					<span>Cust.on</span>
					<h1>
						Fique mais seguro com as transferências em DeFI e
						CeFI.
					</h1>
					<Infos alternative>
						<p>
							<span>DeFI e CeFI</span>
							Acesse sua wallet DeFi ou CeFi e integre na
							Custo.on para ter acesso a uma ambiente com
							segurança máxima, fora do alcance de hackers, e uma
							das maiores coberturas de seguro do mercado.
						</p>
					</Infos>
					<ButtonPattern sideBar>Comece agora</ButtonPattern>
				</Text>
			</BoxImage>
			<Block08 />
		</Container>
	);
};

export default DefiCefi;
