import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 34px;
	margin-bottom: 110px;

	.decoration1,
	.decoration2 {
		position: absolute;

		@media only screen and (max-width: 1025px) {
			display: none;
		}
	}

	.decoration1 {
		bottom: 0;
		right: 37.4%;
	}

	.decoration2 {
		bottom: 150px;
		right: 40.14%;
	}

	@media only screen and (max-width: 1025px) {
		margin-bottom: 42px;
	}
`;

export const Header = styled.div`
	display: flex;
	justify-content: space-between;

	@media only screen and (max-width: 1025px) {
		flex-direction: column;
	}
`;

export const Box = styled.div`
	display: flex;
	flex-direction: column;
	gap: 9px;

	p {
		font-weight: 500;
		font-size: 14px;
		color: #fc9c44;
	}

	a {
		font-weight: 500;
		font-size: 14px;
		text-decoration-line: underline;
		color: #fafafa;
		transition: color 0.25s;

		&:hover {
			color: #fc9c44;
		}

		@media only screen and (max-width: 1025px) {
			line-height: 30px;
		}
	}

	div {
		display: flex;
		gap: 40px;
		margin-top: 45px;

		@media only screen and (max-width: 1025px) {
			margin: 40px 0 35px 0;
		}
	}

	@media only screen and (max-width: 1025px) {
		img {
			width: fit-content;
		}
	}
`;

export const Infos = styled.div`
	display: flex;
	flex-direction: column;

	img {
		width: fit-content;
		margin-top: 29px;
	}
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;

	p {
		font-weight: 500;
		font-size: 14px;
		color: #fafafa;
		line-height: 24px;
	}

	.links {
		a {
			color: #fafafa;
			padding: 0 7px;
			border-left: 1px solid #fafafa;
			transition: 0.5s;

			:first-child {
				padding: 0 7px 0 0;
				border: none;
			}

			:hover {
				color: #fc9c44;
				transition: 0.5s;
				text-decoration: none;
			}

			@media only screen and (max-width: 1025px) {
				line-height: 40px;
			}
		}
	}

	a {
		color: #fc9c44;

		:hover {
			text-decoration: underline;
		}
	}

	@media only screen and (max-width: 1025px) {
		flex-direction: column-reverse;

		p {
			line-height: 27px;
		}

		.links {
			display: flex;
			flex-direction: column;
			margin-top: 15px;

			a {
				padding: 0;
				border: none;
			}
		}
	}
`;
