import { ButtonPattern } from "../../components";
import { Block08 } from "..";
import {
	Container,
	Title,
	Content,
	BoxImage,
	Text,
	Infos,
} from "./styles";

const CustodyQualified: React.FC = () => {
	return (
		<Container>
			<img
				src="./square-decoration2.svg"
				className="decoration1"
				alt=""
			/>
			<img
				src="./decoration3.svg"
				className="decoration2"
				alt=""
				width={4}
				height={4}
			/>
			<img
				src="./decoration3.svg"
				className="decoration3"
				alt=""
				width={4}
				height={4}
			/>
			<Title>
				<span>Custódia qualificada</span>
				<p>
					Projeta seus ativos digitais com a custódia de
					armazenamento Cold Storage e Hot Wallet qualificadas e
					seguras.
				</p>
			</Title>
			<Content>
				<p>
					Na economia das criptomoedas, os hackers estão atrás
					das chaves privadas que autorizam transações para o
					blockchain e permitem assumir todos os ativos digitais
					do usuário. A Cust.on emprega a solução mais eficiente
					para proteger as chaves privadas hoje. O MPC é baseado
					em uma rede segura de vários computadores independentes
					e remotos, seja local ou na nuvem, que estão envolvidos
					na cerimônia de assinatura de qualquer transação
					blockchain.
				</p>
				<ButtonPattern sideBar>Comece agora</ButtonPattern>
			</Content>
			<BoxImage>
				<img
					src="./square-decoration2.svg"
					className="decoration4"
					alt=""
				/>
				<img
					className="img-block"
					src="./img/IMG6.png"
					alt="Cofre Online"
				/>
				<img
					className="img-block-mobile"
					src="./img/IMG6-Mobile.png"
					alt="Cofre Online"
				/>
				<Text>
					<span>Diferenciais</span>
					<h1>
						Segurança baseada em um cofre nunca conectado
						on-line.
					</h1>
					<Infos>
						<p>
							<img src="/check-icon.svg" alt="" />
							Sistema verdadeiro Air-gapped que não pode ser
							hackeado.
						</p>
						<p>
							<img src="/check-icon.svg" alt="" />
							Multisig como processo de assinatura.
						</p>
						<p>
							<img src="/check-icon.svg" alt="" />
							Arivos mantidos em contas segregadas, seguradas e
							por conexão unidirecional, onde os dados só podem
							sair do cofre e nunca entrar.
						</p>
					</Infos>
					<ButtonPattern sideBar>Comece agora</ButtonPattern>
				</Text>
			</BoxImage>
			<Block08 />
		</Container>
	);
};

export default CustodyQualified;
