import React, { HTMLInputTypeAttribute } from "react";
import { Parent, Placeholder, Input } from "./styles";

interface TextFieldProps {
  value?: string;
  name: string;
  changeValue?: any;
  placeholder: string;
  type?: HTMLInputTypeAttribute;
};

const maskPhone = (value: string) => {
  return value
    .replace(/\D/g, "")
    .replace(/^(\d{2})(\d)/g, "($1) $2")
    .replace(/(\d{5})(\d{4})(\d)/, "$1-$2");
};

const PhoneInput: React.FC<TextFieldProps> = ({
  type,
  value,
  name,
  placeholder,
  changeValue,
}) => {
  return (
    <Parent>
      <Input
        type={type}
        name={name}
        id={type}
        placeholder=" "
        onChange={(e) => changeValue(maskPhone(e.target.value))}
        value={value}
      />
      <Placeholder htmlFor={type}>{placeholder}</Placeholder>
    </Parent>
  );
}

export default PhoneInput;
