import Link from "next/link";
import { Container, Infos, Top } from "./styles";

interface CardStepProps {
	img: string;
	title: string;
	text: string;
	full?: boolean;
	soon?: boolean;
	textRequest?: string;
	url?: string;
	width?: number;
	height?: number;
}

const CardStep: React.FC<CardStepProps> = ({
	img,
	title,
	text,
	full,
	soon,
	textRequest,
	url = "",
	width,
	height,
}) => {
	return (
		<Container full={full}>
			<Infos soon={soon}>
				<Top>
					<div>
						<img
							src={img}
							alt=""
							width={width}
							height={height}
						/>
					</div>
					{soon && <p>Em breve</p>}
				</Top>
				<span>{title}</span>
				<p>{text}</p>
			</Infos>
			<Link href={url}>
				<a aria-label={title}>
					{textRequest && (
						<button disabled={soon}>{textRequest}</button>
					)}
				</a>
			</Link>
		</Container>
	);
};

export default CardStep;
