import { Container, Box } from "./styles";

interface CardProps {
	abbreviation: string;
	name: string;
	value: number;
	variation: number;
  run: boolean;
  width?: number;
	height?: number;
}

const CardCrypto: React.FC<CardProps> = ({
	abbreviation,
	name,
	value,
	variation,
  run,
  width,
  height,
}) => {
	const formatBRL = (value: string) => {
		const brl = Number(value.replace(".", "")) / 100;
		const brlStr = brl.toLocaleString("pt-BR", {
			style: "currency",
			currency: "BRL",
		});

		return brlStr.replace("R$", "");
	};

	return (
		<Container>
			<Box>
				<p>1 {abbreviation.toUpperCase()}</p>
				<span>{name}</span>
			</Box>
			<Box>
				<p>{formatBRL(Number(value).toFixed(2))} BRL</p>
				{variation > 0 ? (
					<div>
						<img
							src="/graphic-up.svg"
							alt=""
							width={17}
							height={9}
						/>
						<span className="variation-up">
							+{Number(variation).toFixed(2).replace(".", ",")}
						</span>
					</div>
				) : (
					<div>
						<img
							src="/graphic-down.svg"
							alt=""
							width={17}
							height={9}
						/>
						<span className="variation-down">
							{Number(variation).toFixed(2).replace(".", ",")}
						</span>
					</div>
				)}
			</Box>
		</Container>
	);
};

export default CardCrypto;
