import React from "react";
import { ErrorMessage, Parent, Placeholder, SelectCustom } from "./styles";

interface OptionProps {
  value: string | number;
  label: string;
  disabled?: boolean;
}

export type SelectProps = {
  options: OptionProps[];
  placeholder: string;
  value: string;
  small?: boolean;
  disabled?: boolean;
  error?: string;
  onChange: (value: string) => void;
};

const Select: React.FC<SelectProps> = ({
  disabled,
  options,
  placeholder,
  small,
  error,
  value,
  onChange,
}) => {
  const isFilled = !!value;

  return (
    <Parent small={small} error={error ? true : false} filled={isFilled}>
      <Placeholder error={error ? true : false} filled={isFilled}>
        {placeholder}
      </Placeholder>

      <SelectCustom
        disabled={disabled}
        filled={isFilled}
        value={value}
        onChange={e => onChange(e.target.value)}>
        <option value="" disabled>
          Selecione
        </option>

        {options.map((item, index) => (
          <option key={index} value={item.value} disabled={item.disabled}>
            {item.label}
          </option>
        ))}
      </SelectCustom>

      <img src="/arrow-alternative.svg" alt="" />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Parent>
  );
};

export default Select;
