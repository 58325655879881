import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 80px;
  margin-bottom: 80px;
  position: relative;

  @media only screen and (max-width: 1025px) {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 80px;
    margin-bottom: 10px;

    .orangesquare {
      display: none;
    }
  }
`;

export const Left = styled.article`
  width: 75%;

  @media only screen and (max-width: 1025px) {
    width: 100%;
  }

  span {
    font-weight: 500;
    font-size: 14px;
    color: #fc9c44;
  }

  h1 {
    text-align: left;
    font-weight: 700;
    font-size: 42px;
    line-height: 50px;
    color: #fafafa;
    margin: 8px 0 16px 0;

    @media only screen and (max-width: 1025px) {
      width: 100%;
      font-size: 30px;
      line-height: 36px;
    }
  }

  .orangeSquare {
    position: absolute;
    right: 0;
    bottom: 0;
  }
`;

export const Right = styled.div`
  position: absolute;
  right: -6vw;
  top: 120px;

  .orangeSquare {
    position: absolute;
    left: -120px;
    top: -40px;
  }

  @media only screen and (max-width: 1025px) {
    display: none;
  }
`;

export const Content = styled.article`
  width: calc(90vw - 200px);
  padding-left: 200px;

  @media only screen and (max-width: 1025px) {
    flex-direction: column;
    padding-left: 0;
    width: 100%;

    img {
      max-width: 100%;
    }
  }

  p {
    text-align: left;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #8c96a0;
    margin-bottom: 42px;
  }

  @media only screen and (max-width: 1025px) {
    width: 100%;

    .buttonBlock17 {
      width: 315px;
      justify-content: center;

      @media only screen and (max-width: 600px) {
        width: 100%;
      }
    }
  }
`;

export const OrangeDot = styled.article`
  img {
    margin: 0 0 0 0;
  }

  @media only screen and (max-width: 1025px) {
    display: flex;
    justify-content: center;
    padding-top: 57px;
    padding-left: 180px;
  }
`;
