import { useEffect } from "react";
import { ButtonPattern } from "../../components";
import { Container, Title, Content } from "./styles";

const Block07: React.FC = () => {
	useEffect(() => {
		if (typeof window !== "undefined") {
			var element = document.getElementById("crypto");
			var content = [
				"Bitcoin...",
				"Ethereum...",
				"Cardano...",
				"Ripple...",
			];
			var part = 0;
			var partIndex = 0;
			var interval = setInterval(Type, 100);
		}

		function Type() {
			var text = content[part].substring(0, partIndex + 1);
			element!.innerHTML = text;
			partIndex++;

			if (text === content[part]) {
				clearInterval(interval);
				setTimeout(function () {
					interval = setInterval(Delete, 50);
				}, 1000);
			}
		}

		function Delete() {
			var text = content[part].substring(0, partIndex - 1);
			element!.innerHTML = text;
			partIndex--;

			if (text === "") {
				clearInterval(interval);

				if (part == content.length - 1) part = 0;
				else part++;

				partIndex = 0;

				setTimeout(function () {
					interval = setInterval(Type, 100);
				}, 200);
			}
		}
	}, []);

	return (
		<Container>
			<img
				src="./decoration2.svg"
				className="decoration1"
				alt=""
			/>
			<img
				src="./decoration3.svg"
				className="decoration2"
				alt=""
				width={4}
				height={4}
			/>
			<Title>
				<span>Como é o processo de custódia</span>
				<p>
					Custodie tokens, smart contracts e criptomoedas como{" "}
					<label id="crypto" />
				</p>
			</Title>
			<Content>
				<img
					src="./img/block7.png"
					alt="Pitch"
					className="img-desk"
					width={1223}
					height={582}
				/>
				<img
					src="./img/block7-mobile.png"
					className="img-mobile"
					alt="Pitch"
					width={362}
					height={992}
				/>
				<div>
					<ButtonPattern sideBar>Comece agora</ButtonPattern>
					<a
						href="/Pitch_Custon_2022.pdf"
						download="Pitch Custon 2022"
					>
						Baixe o pitch
					</a>
				</div>
			</Content>
		</Container>
	);
};

export default Block07;
