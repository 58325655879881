import { useContext } from "react";
import AppContext from "../../helper/context";

import { Container } from "./styles";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: any;
  width?: number;
  alternative?: boolean;
  sideBar?: boolean;
}

const ButtonPattern: React.FC<ButtonProps> = ({
  children,
  width,
  alternative,
  onClick,
  sideBar,
  type = "button",
  className,
  disabled,
}) => {
  const context = useContext(AppContext);
  return (
    <Container
      type={type}
      width={width}
      className={alternative ? "alternative" : className}
      onClick={() =>
        sideBar ? context?.setSideBar(!context.sideBar) : onClick
      }
      disabled={disabled}
    >
      {children}
    </Container>
  );
};

export default ButtonPattern;
