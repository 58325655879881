import { ButtonPattern } from "../../components";
import { Container, Text, Picture } from "./styles";

const Block13: React.FC = () => {
  return (
    <Container>
      <Picture>
        <img src="./img/IMG5.png" alt="Homem no computador" className="img-block" />
        <img src="./img/IMG5-Mobile.png" alt="Homem no computador" className="img-block-mobile" />
      </Picture>
      <Text>
        <h3> Elimine a superfície de ataque </h3>
        <p>
          Nosso cofre frio é um <span>Air Gapped</span> que permanece offline
          durante todo o processo de transação, isolando completamente seus
          ativos dos invasores.
        </p>
        <h3> Alto padrão de segurança para controle de risco </h3>
        <p>
          Nossa abordagem de gerenciamento de riscos garante que
          nossas soluções atendam aos mais altos padrões de
          segurança e regulatórios.
        </p>
        <h3> Transacione 1.000 vezes mais rápido </h3>
        <p>
          Nossa solução MPC oferece a capacidade de lidar com taxa de
          transferência de até <span>7500 assinaturas</span> por segundo em
          comparação com a normalidade da indústria de 7-8 por segundo.
        </p>
        <ButtonPattern sideBar>Comece agora</ButtonPattern>
      </Text>
    </Container>
  );
};

export default Block13;
