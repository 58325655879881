import styled from "styled-components";

export const Container = styled.div`
  margin-top: 101px;
  margin-bottom: 139px;
  display: flex;
  flex-direction: column;
  gap: 58px;

  @media only screen and (max-width: 1025px) {
    margin-top: 32px;
  }
`;

export const Title = styled.p`
  font-weight: 600;
  font-size: 18px;
  color: #fafafa;

  span {
    color: #fc9c44;
  }
`;

export const Soon = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Carousel = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  gap: 28px;
  width: 100%;

  @media only screen and (min-width: 1025px) {
    width: calc(100% - 16vw);
  }

  @media only screen and (max-width: 1025px) {
    gap: 15px;
  }  
`;

export const OrangeDot = styled.article`
  img {
    margin: 0 0 0 0;
  }

  @media only screen and (max-width: 1025px) {
    display: flex;
    justify-content: center;
    padding-top: 57px;
    padding-left: 180px;
  }
`;
