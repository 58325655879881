import { CardStep } from "../../components";

import { Container, Content, Line, Title } from "./styles";

const Block05: React.FC = () => {
	return (
		<Container>
			<img
				src="./2nd-decoration.svg"
				alt="2nd Logo"
				className="decoration1"
				width={235}
				height={235}
			/>
			<img
				src="./decoration3.svg"
				className="decoration3"
				alt=""
				width={4}
				height={4}
			/>
			<img
				src="./square-decoration.svg"
				className="decoration4"
				alt=""
				width={236}
				height={236}
			/>
			<Title>
				<span>Como transferir seus fundos</span>
				<h1>
					Diferentes componentes de autorização que fornecem
					várias camadas de segurança protegendo seus ativos.
				</h1>
			</Title>
			<Content>
				<Line>
					<CardStep
						img="/step1-icon.svg"
						title="Step 1"
						text="Solicite a ordem de retirada dos fundos."
					/>
					<CardStep
						img="/step2-icon.svg"
						title="Step 2"
						text="Validação das instruções e solicitação
            de transação pela Cust.on."
					/>
					<CardStep
						img="/step3-icon.svg"
						title="Step 3"
						text="Revisão e aprovação das instruções emitidas. Confirmação de detalhes da transação."
					/>
				</Line>
				<Line center>
					<CardStep
						img="/step4-icon.svg"
						title="Step 4"
						text="A Cust.on acessa com segurança chaves privadas para executar as instruções do cliente por meio de assinatura."
					/>
					<CardStep
						img="/step5-icon.svg"
						title="Step 5"
						text="Aprovação final do cliente e transferência de fundos pela Cust.on."
					/>
				</Line>
			</Content>
		</Container>
	);
};

export default Block05;
