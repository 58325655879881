import { Container } from "./styles";

interface SlideProps {
	img: string;
	text: string;
	width?: number | undefined;
	height?: number | undefined;
}

const Slide: React.FC<SlideProps> = ({
	img,
	text,
	width,
	height,
}) => {
	return (
		<Container>
			<div>
				<img src={img} alt="" width={width} height={height} />
			</div>
			<p>{text}</p>
		</Container>
	);
};

export default Slide;
