import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 100px;

  @media only screen and (max-width: 1025px) {
    display: none;
  }
`;

export const Left = styled.div`
  width: 50%;

  span {
    font-weight: 500;
    font-size: 14px;
    color: #fc9c44;
  }

  h1 {
    text-align: left;
    font-weight: 700;
    font-size: 42px;
    line-height: 50px;
    color: #fafafa;
    margin: 8px 0 16px 0;
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #8c96a0;
    margin: 18px 0 31px 0;
  }

  @media only screen and (max-width: 1025px) {
    h1 {
      font-size: 30px;
      line-height: 36px;
    }
  }
`;

export const Image = styled.div`
  position: relative;
`;

export const Text = styled.div`
  max-width: 34vw;

  span {
    font-weight: 500;
    font-size: 14px;
    color: #fafafa;
  }

  h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 21.94px;
    color: #fafafa;
    margin-top: 8px;
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #8c96a0;
    margin: 18px 0 31px 0;
  }
`;

export const BoxCard = styled.div`
  display: flex;
  justify-content: center;
  gap: 28px;
  margin-top: 55px;

  @media only screen and (max-width: 1025px) {
    flex-direction: column;
    gap: 9px;
  }
`;
