import styled from "styled-components";

export const Container = styled.div`
  margin-top: 186px;
  display: flex;
  flex-direction: column;
  gap: 58px;
`;

export const Title = styled.p`
  font-weight: 600;
  font-size: 18px;
  color: #fafafa;

  span {
    color: #fc9c44;
  }
`;

export const Carousel = styled.div`
  display: flex;
  gap: 28px;

  .swiper {
		width: 100%;
	}

  @media only screen and (max-width: 1025px) {
    gap: 15px;
    overflow: auto;
  }
`;
