import { ButtonPattern } from "../../components";
import { Block08 } from "..";
import {
	Container,
	Title,
	Content,
	BoxImage,
	Text,
	Infos,
} from "./styles";

const SelfManaged: React.FC = () => {
	return (
		<Container>
			<img
				src="./square-decoration2.svg"
				className="decoration1"
				alt=""
			/>
			<img
				src="./decoration3.svg"
				className="decoration2"
				alt=""
				width={4}
				height={4}
			/>
			<img
				src="./decoration3.svg"
				className="decoration3"
				alt=""
				width={4}
				height={4}
			/>
			<Title>
				<span>Custódia Self-Managed</span>
				<p>
					Gerenciamento e controle sobre as suas chaves privadas.
				</p>
			</Title>
			<Content>
				<p>
					Nós fornecemos uma ampla grade de funcionalidades
					dedicada para aqueles que desejam possuir controle
					sobre a gestão de suas chaves e acesso ao
					armazenamento.
				</p>
				<p>
					Defina as funções para acessar os ativos da sua
					organização:
				</p>
				<Infos gap={8}>
					<p>
						<img src="/check-icon.svg" alt="" />
						Introduza limites por aprovador
					</p>
					<p>
						<img src="/check-icon.svg" alt="" />
						Limites de gastos
					</p>
					<p>
						<img src="/check-icon.svg" alt="" />
						Permissões para envio
					</p>
					<p>
						<img src="/check-icon.svg" alt="" />
						Exigências para movimentação
					</p>
				</Infos>
				<ButtonPattern sideBar>Comece agora</ButtonPattern>
			</Content>
			<BoxImage>
				<img
					src="./square-decoration2.svg"
					className="decoration4"
					alt=""
				/>
				<img
					className="img-block"
					src="./img/IMG7.png"
					alt="Mulher no celular"
				/>
				<img
					className="img-block-mobile"
					src="./img/IMG7-Mobile.png"
					alt="Mulher no celular"
				/>
				<Text>
					<span>Diferenciais</span>
					<h1>
						Movimente todos os seus ativos de forma segura e
						contínua.
					</h1>
					<Infos>
						<p>
							<img src="/check-icon.svg" alt="" />
							Transfira facilmente entre as carteiras frias e
							quentes, protegidas pelo sisema multi-assinatura da
							Cust.ON.
						</p>
						<p>
							<img src="/check-icon.svg" alt="" />
							Crie chaves privadas, geradas em um ambiente
							off-line e nunca expostas em redes conectadas a
							internet.
						</p>
						<p>
							<img src="/check-icon.svg" alt="" />
							Configure o acesso a carteira de acordo com o nóvel
							organizacional, com regras definidas pelo próprio
							cliente.
						</p>
					</Infos>
					<ButtonPattern sideBar>Comece agora</ButtonPattern>
				</Text>
			</BoxImage>
			<Block08 />
		</Container>
	);
};

export default SelfManaged;
