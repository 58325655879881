import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 90px;
  margin-top: 50px;
  margin-bottom: 100px;

  
  @media only screen and (max-width: 1025px) {
    display: none;
  }
`;


export const Picture = styled.div`
  position: relative;

  .img-block {
    display: flex;
  }

  .img-block-mobile {
    display: none;
  }

  @media only screen and (max-width: 1025px) {
    display: flex;

    .img-block-mobile {
      display: flex;
    }    

    .img-block {
      display: none;
    }
  }

`;

export const Text = styled.div`
  max-width: 34vw;

  span {
    font-weight: 500;
    font-size: 14px;
    color: #fafafa;
  }

  h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 21.94px;
    color: #fafafa;
    margin-top: 8px;
  }

  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #8c96a0;
    margin: 18px 0 31px 0;
  }
`;
