import { Container, Content, Navigator, Left } from "./styles";

const BlockGlossario: React.FC = () => {

  const sections = ["0-9", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "X", "Y", "Z", "W"]

  return (
    <Container>
      <Left>
        <span>Recursos</span>
        <h1>Glossário</h1>
      </Left>
      <Content>
        <Navigator>
          <ul>
            {
              sections.map((value) => {
                return <li key={value}>
                  <a href={`#${value}`}>{value}</a>
                </li>
              })
            }
          </ul>
        </Navigator>
      </Content>
    </Container>
  );
};

export default BlockGlossario;
