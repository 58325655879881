import styled from "styled-components";

export const LoaderContainer = styled.div`
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BoxLoader = styled.div`
  height: 120px;
  width: 120px;
  position: relative;

  @keyframes anim_one {
    0%,
    100% {
      top: 0;
      left: 0;
    }
    25% {
      top: 0;
      left: calc(100% - 30px);
    }
    50% {
      left: calc(100% - 100px);
      top: calc(100% - 100px);
      height: 70px;
      width: 70px;
      background: transparent;
    }
    75% {
      left: 0;
      top: calc(100% - 30px);
    }
  }

  @keyframes anim_two {
    0%,
    100% {
      left: calc(100% - 100px);
      top: calc(100% - 100px);
      height: 70px;
      width: 70px;
    }
    25% {
      top: calc(100% - 30px);
      left: 0;
    }
    50% {
      left: 0;
      top: 0;
      height: 30px;
      width: 30px;
      background: transparent;
    }
    75% {
      left: calc(100% - 30px);
      top: 0;
    }
  }

  .square {
    position: absolute;
    border-radius: 0%;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);

    &.first {
      height: 30px;
      width: 30px;
      animation: anim_one 3s linear infinite;
      border: 8px solid #fa8231;
    }

    &.second {
      left: calc(100% - 100px);
      top: calc(100% - 100px);
      height: 70px;
      width: 70px;
      animation: anim_two 3s linear infinite;
      border: 8px solid #fc9c44;
    }
  }
`;
