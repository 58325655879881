import { Container, Box, IconBox, SoonBox } from "./styles";

interface TabProps {
	cryptos: any;
}

const TabCrypto: React.FC<TabProps> = ({ cryptos }) => {
	const formatBRL = (value: string) => {
		const brl = Number(value.replace(".", "")) / 100;
		const brlStr = brl.toLocaleString("pt-BR", {
			style: "currency",
			currency: "BRL",
		});

		return brlStr.replace("R$", "");
	};

	const Variation = (variation: string | any) => {
		const variationNumber = variation.variation;
		return (
			<>
				{variationNumber >= 0 ? (
					<div>
						<img
							src="/graphic-up.svg"
							alt=""
							width={17}
							height={9}
						/>
						<span className="variation-up">
							+
							{Number(variationNumber)
								.toFixed(2)
								.replace(".", ",")}
						</span>
					</div>
				) : (
					<div>
						<img
							src="/graphic-down.svg"
							alt=""
							width={17}
							height={9}
						/>
						<span className="variation-down">
							-
							{Number(variationNumber)
								.toFixed(2)
								.replace(".", ",")}
						</span>
					</div>
				)}
			</>
		);
	};

	return (
		<Container>
			<Box>
				<table>
					<thead>
						<tr>
							<th>Ativo</th>
							<th>Ticker</th>
							<th>Valor</th>
							<th>Variab. 24h</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{cryptos?.map((crypto: any, key: number) => (
							<tr key={key}>
								<td>
									<IconBox>
										{(() => {
											switch (crypto.abbreviation) {
												case "btc":
													return (
														<img src="/btc-circle.svg" alt="" />
													);
												case "eth":
													return (
														<img src="/eth-circle.svg" alt="" />
													);
												case "usdt":
													return (
														<img src="/usdt-circle.svg" alt="" />
													);
												case "ada":
													return (
														<img src="/ada-circle.svg" alt="" />
													);
												case "sol":
													return (
														<img src="/rpp-circle.svg" alt="" />
													);
												default:
													return null;
											}
										})()}
										<div className="mobileName">
											<span className="mobileData">
												{crypto.name}
											</span>
											{crypto.abbreviation.toUpperCase()}
										</div>
									</IconBox>
								</td>
								<td className="mobileHidden">
									<div>{crypto.name}</div>
								</td>
								<td className="lastMobile">
									<div>
										{formatBRL(Number(crypto.buy).toFixed(2))}{" "}
										BRL
									</div>
									<div className="mobileData">
										<Variation
											variation={crypto.variation as string}
										/>
									</div>
								</td>
								<td className="mobileHidden">
									<Variation
										variation={crypto.variation as string}
									/>
								</td>
								<td className="transferColumn mobileHidden">
									<SoonBox>
										<button type="button">Transferir</button>
										<span>Em breve</span>
									</SoonBox>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</Box>
		</Container>
	);
};

export default TabCrypto;
