import styled from "styled-components";

export const Container = styled.div`
  
  @media only screen and (max-width: 1025px) {
    min-width: 210px;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: row;
  text-align: left; 
  gap: 10px;
  align-content: end;
  margin-bottom: -60px;

  table {
    width: 100%;
    border: none;

    thead {

      @media only screen and (max-width: 1025px) {
        display: none;
      }

      th {
        font-size: 16px;
        font-weight: 600;
        color: #FAFAFA;
        line-height: 19px; 
      }
    }

    tbody {

      tr {
        
        td {
          border-bottom: 1px solid #1E2C39;
          font-weight: 500;
          font-size: 14px;
          color: #5a6473;
          padding: 15px 0;

          &.mobileHidden {
            @media only screen and (max-width: 1025px) {
              display: none;
            }
          }

          &.transferColumn {
            width: 115px;
          }

          &.lastMobile {
            
            @media only screen and (max-width: 1025px) {
              * {
                text-align: right;
              }
            }
          }

          .mobileData {

            @media only screen and (min-width: 1025px) {
              display: none;
            }
          }

          .mobileName {
            @media only screen and (max-width: 1025px) {
              display: flex;
              flex-direction: column;
            }
          }
        }
      }
    }
  }

  :last-child {
    text-align: left;
  }

  span {
    font-weight: 600;
    font-size: 12px;
    color: #5a6473;

    &.variation-up {
      color: #4bd281;
    }

    &.variation-down {
      color: #dc3545;
    }
  }
`;

export const IconBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const SoonBox = styled.div`
  width: 115px;
  position: relative;

  span {
    position: absolute;
    z-index: 2;
    right: -80px;
    top: -24px;
    border: 1px solid #FC9C44;
    background-color: #1E2C39;
    padding: 9px 16px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    color: #FC9C44;
    box-shadow: 0px 10px 25px rgba(252, 156, 68, 0.12);
    border-radius: 2px;
    opacity: 0;
    visibility: hidden;
    transition: opacity .25s;
  }

  button {
    background-color: #1E2C39;
    color: #5A6473;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    padding: 14px 24px;
    border: none;

    &:hover ~ span {
      opacity: 1;
      visibility: visible;
    }
  }
`;

