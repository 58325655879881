import styled from "styled-components";

export const Container = styled.div`
  background-color: #0f1e2d;
  width: 100%;
  height: 100%;
  
  display: flex;
  align-items: center;
  padding-top: 45px;
  flex-direction: column;
  gap: 15px;

  div {
    width: 110px;
    height: 110px;
    border: 1px solid #1e2c39;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  p {
    font-weight: 500;
    font-size: 16px;
    color: #fafafa;
  }
`;
